import { FileType, MediaType } from "@knowt/syncing/graphql/schema";

export const getMimeType = (extension: string) => {
    switch (extension) {
        case "doc":
            return "application/msword";
        case "docx":
            return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        case "ppt":
            return "application/vnd.ms-powerpoint";
        case "pptx":
            return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        case "xls":
            return "application/vnd.ms-excel";
        case "xlsx":
            return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        case "pdf":
            return "application/pdf";
        case "txt":
            return "text/plain";
        case "mp4":
            return "video/mp4";
        case "webm":
            return "video/webm";
        case "m4a":
            return "audio/mp4";
        case "mov":
            return "video/quicktime";
        case "mkv":
            return "video/x-matroska";
        case "avi":
            return "video/x-msvideo";
        case "wmv":
            return "video/x-ms-wmv";
        case "mp3":
            return "audio/mpeg";
        case "wav":
            return "audio/wav";
        case "ogg":
            return "audio/ogg";
        case "jpeg":
            return "image/jpeg";
        case "png":
            return "image/png";
        case "svg":
            return "image/svg+xml";
    }
};

export const fileTypeFromMimeType = (mimeType: string) =>
    ({
        "application/pdf": "pdf",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
        "application/vnd.ms-powerpoint": "ppt",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
        "application/vnd.ms-excel": "xls",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
        "application/msword": "doc",
        "application/vnd.google-apps.presentation": "pptx",
        "application/vnd.google-apps.document": "docx",
        "application/vnd.google-apps.spreadsheet": "xlsx",
        "image/jpeg": "jpg",
        "image/png": "png",
        "image/gif": "gif",
        "image/bmp": "bmp",
        "image/webp": "webp",
        "video/mp4": "mp4",
        "video/quicktime": "mov",
        "video/x-matroska": "mkv",
        "video/x-msvideo": "avi",
        "video/x-ms-wmv": "wmv",
        "text/plain": "txt",
        "audio/mpeg": "mp3",
        "audio/wav": "wav",
        "audio/ogg": "ogg",
        "audio/mp3": "mp3",
        "audio/mp4": "m4a",
        "audio/x-m4a": "m4a",
        "video/webm": "webm",
        "audio/webm": "webm",
        "image/svg+xml": "svg",
    })[mimeType];

export const generalizedMediaTypeFromSingleFileType = (fileType: string) => {
    switch (fileType?.toLowerCase()) {
        case "pdf":
        case "pptx":
        case "ppt":
            return MediaType.PDF;
        case "mp4":
        case "mov":
        case "mkv":
        case "avi":
        case "wmv":
        case "webm":
            return MediaType.VIDEO;
        case "mp3":
        case "wav":
        case "ogg":
        case "m4a":
            return MediaType.AUDIO;
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
        case "bmp":
        case "webp":
            return MediaType.IMAGE;
        default:
            return MediaType.VIDEO;
    }
};

export const SUPPORTED_IMAGE_MIME_TYPES = [
    // .png
    "image/png",
    // .jpg
    "image/jpeg",
    // .svg
    "image/svg+xml",
];

export const SUPPORTED_NOTE_MIME_TYPES = [
    // .docx
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    // .doc
    "application/msword",
    // .txt
    "text/plain",
];

export const SUPPORTED_PPT_MIME_TYPES = [
    // .pptx
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    // .ppt
    "application/vnd.ms-powerpoint",
];

export const SUPPORTED_XLS_MIME_TYPES = [
    // .xlsx
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // .xls
    "application/vnd.ms-excel",
];

export const SUPPORTED_MEDIA_MIME_TYPES = [
    // .mp4
    "video/mp4",
    // .mov
    "video/quicktime",
    // .webm
    "video/webm",
    // .mkv
    "video/x-matroska",
];

export const SUPPORTED_AUDIO_MIME_TYPES = [
    // .mp3
    "audio/mpeg",
    // .wav
    "audio/wav",
    // .ogg
    "audio/ogg",
    // .m4a
    "audio/x-m4a",
    // .mp4
    "audio/mp4",
];

export const SUPPORTED_PDF_MIME_TYPES = [
    // .pdf
    "application/pdf",
];

export const SUPPORTED_FILE_MIME_TYPES = [
    ...SUPPORTED_PPT_MIME_TYPES,
    ...SUPPORTED_PDF_MIME_TYPES,
    ...SUPPORTED_AUDIO_MIME_TYPES,
    ...SUPPORTED_MEDIA_MIME_TYPES,
];

export const SUPPORTED_CHAT_ATTACHMENT_MIME_TYPES = [...SUPPORTED_IMAGE_MIME_TYPES, ...SUPPORTED_AUDIO_MIME_TYPES];

export const getExtensionFromUrl = (url: string) => {
    if (!url) return undefined;

    const splitUrl = url.split(".");
    return splitUrl[splitUrl.length - 1];
};

export const acceptedVideoExtensions = [".mp4", ".mov", ".webm", ".mkv"];
export const acceptedAudioExtensions = [".mp3", ".wav", ".ogg"];

export const ACCEPTED_FILE_TYPES: Record<string, string[]> = {
    "application/pdf": [".pdf"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
    "application/vnd.ms-powerpoint": [".ppt"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    "application/msword": [".doc"],
    "text/plain": [".txt"],
    // video files
    "video/mp4": [".mp4"],
    "video/quicktime": [".mov"],
    "video/webm": [".webm"],
    "video/x-matroska": [".mkv"],
    // audio files
    "audio/mpeg": [".mp3"],
    "audio/wav": [".wav"],
    "audio/ogg": [".ogg"],
    "audio/mp4": [".m4a"],
    "audio/x-m4a": [".m4a"],
};

/*
export enum FileType {
    MP3 = "MP3",
    MP4 = "MP4",
    MKV = "MKV",
    MOV = "MOV",
    WAV = "WAV",
    WEBM = "WEBM",
    PDF = "PDF",
    M4A = "M4A",
    DOCX = "DOCX",
    DOC = "DOC",
    PPTX = "PPTX",
    PPT = "PPT",
    XLSX = "XLSX",
    XLS = "XLS",
}
    */

export const getDisplayNameFromFileType = (fileType: FileType) => {
    switch (fileType) {
        case FileType.MP3:
        case FileType.WAV:
        case FileType.WEBM:
            return "audio";

        case FileType.MP4:
        case FileType.MKV:
        case FileType.MOV:
        case FileType.M4A:
            return "video";

        case FileType.PDF:
            return "PDF";
        case FileType.DOCX:
        case FileType.DOC:
            return "document";
        case FileType.PPTX:
        case FileType.PPT:
            return "presentation";
        case FileType.XLSX:
        case FileType.XLS:
            return "spreadsheet";
        default:
            return "file";
    }
};
