import {
    addMediaS3UserTag,
    createMediaFromYoutube,
    createMediaWithTranscription,
    deleteMedia,
    duplicateMedia,
    editChapter,
    generateMediaTranscription,
    updateMedia,
} from "@/graphql/mutations";
import { getMedia, listMedia, listMediaByFolder, listMediasByClass } from "@/graphql/queries";
import { platform } from "@/platform";
import {
    AddMediaS3UserTagInput,
    CreateMediaFromYoutubeInput,
    CreateMediaWithTranscriptionInput,
    DuplicateMediaInput,
    Media,
} from "@knowt/syncing/graphql/schema";
import { client, listGroupedData, ServerClientWithCookies } from "@/utils/client/graphql";
import { retry } from "@/utils/genericUtils";
import { deepScrapeEmptyFields, objectWithout, scrapeEmptyFields } from "@/utils/dataCleaning";
import { v4 as uuidv4 } from "uuid";
import { AV_BUCKET } from "./constants";
import { now } from "@/utils/dateTimeUtils";

const cleanMediaUpdateInput = input =>
    deepScrapeEmptyFields(objectWithout(input, "rating", "ratingCount", "bucket", "jwt", "views"), [
        "noteId",
        "flashcardSetId",
        "folderId",
        "classId",
        "fileType",
        "embedUrl",
        "embedCommands",
        "subject",
        "topic",
        "exam_v2",
        "examUnit",
        "examSection",
        "tags",
        "password",
        "description",
    ]) as Partial<Media>;

export const callGetMedia = async ({
    mediaId,
    serverClient,
    password,
}: {
    mediaId: string;
    serverClient?: ServerClientWithCookies;
    password?: string;
}) => {
    if (!mediaId) return undefined;

    const input = { mediaId, password };

    return client
        .query({
            query: getMedia,
            variables: { input },
            serverClient,
        })
        .then(({ data }) => (data.getMedia as Media) ?? undefined)
        .catch(async error => {
            const { report } = await platform.analytics.logging();
            report(error, "fetchMedia", input);
            throw error;
        });
};

export const callListMedia = async ({
    userId,
    serverClient,
}: {
    userId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        listQuery: listMedia,
        groupingKey: "mediaId",
        input: { userId },
        queryName: "listMedia",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, Media>;
};

export const callCreateMediaWithTranscription = async (mediaInput: CreateMediaWithTranscriptionInput) => {
    const result = await retry(() => {
        const timestamp = now();

        const input = { created: timestamp, updated: timestamp, public: false, ...mediaInput };

        return client
            .mutate({
                mutation: createMediaWithTranscription,
                variables: { input },
            })
            .then(({ data }) => data.createMediaWithTranscription);
    });

    return result;
};

export const callCreateMediaFromYoutube = async (input: CreateMediaFromYoutubeInput) => {
    const mediaId = await client
        .mutate({
            mutation: createMediaFromYoutube,
            variables: { input },
        })
        .then(({ data }) => data.createMediaFromYoutube);

    return mediaId;
};

export const callUpdateMedia = async ({
    mediaId,
    userId,
    mediaInput,
}: {
    mediaId: string;
    userId: string | undefined;
    mediaInput: Partial<Media>;
}) => {
    if (!userId) throw new Error("userId is undefined");

    // Perform the actual mutation
    const result = await retry(() => {
        const input = cleanMediaUpdateInput({ mediaId, userId, ...mediaInput });
        return client.mutate({
            mutation: updateMedia,
            variables: {
                input: {
                    updated: now().toString(),
                    ...input,
                },
            },
        });
    });

    return result.data.updateMedia;
};

export const callDeleteMedia = async ({ mediaId, userId }: { mediaId: string; userId: string | undefined }) => {
    const input = { mediaId, userId };

    return await retry(() =>
        client
            .mutate({
                mutation: deleteMedia,
                variables: { input },
            })
            .catch(async error => {
                const { report } = await platform.analytics.logging();
                report(error, "deleteMedia", input);
                throw error;
            })
    );
};

export const callEditMediaChapter = async ({
    mediaId,
    chapterIndex,
    chapterTitle,
}: {
    mediaId: string;
    chapterIndex: number;
    chapterTitle?: string | undefined;
}) => {
    const input = { mediaId, idx: chapterIndex, title: chapterTitle, bucket: AV_BUCKET };

    return await retry(() =>
        client
            .mutate({
                mutation: editChapter,
                variables: { input },
            })
            .catch(async error => {
                const { report } = await platform.analytics.logging();
                report(error, "editMediaChapter", input);
                throw error;
            })
    );
};

export const callDuplicateMedia = async ({
    baseMediaId,
    newMediaId,
    folderId,
}: {
    baseMediaId: string;
    newMediaId?: string;
    folderId?: string;
}) => {
    const input: DuplicateMediaInput = scrapeEmptyFields({ baseMediaId, mediaId: newMediaId ?? uuidv4(), folderId });

    return client
        .mutate({
            mutation: duplicateMedia,
            variables: { input },
        })
        .then(({ data }) => data.duplicateMedia)
        .catch(async error => {
            const { report } = await platform.analytics.logging();
            report(error, "duplicateMedia", input);
            throw error;
        });
};

export const callListMediaByFolder = async ({
    folderId,
    password,
    serverClient,
}: {
    folderId: string;
    password?: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        listQuery: listMediaByFolder,
        groupingKey: "mediaId",
        input: { folderId, password },
        queryName: "listMediaByFolder",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, Media>;
};

export const callListMediasByClass = async ({
    classId,
    serverClient,
}: {
    classId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        listQuery: listMediasByClass,
        groupingKey: "mediaId",
        input: { classId },
        queryName: "listMediasByClass",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, Media>;
};

export const callGenerateMediaTranscription = async ({ mediaId }) => {
    const input = {
        mediaId,
    };

    return await client.mutate({
        mutation: generateMediaTranscription,
        variables: { input },
    });
};

export const callAddMediaS3UserTag = async (input: AddMediaS3UserTagInput) => {
    return await client.mutate({
        mutation: addMediaS3UserTag,
        variables: { input },
    });
};
