export const themeColors = {
    // Primaries
    primary: "var(--color-primary)",
    primaryLight: "var(--color-primary-light)",
    primaryDark: "var(--color-primary-dark)",
    primary2: "var(--color-primary-2)",
    primary3: "var(--color-primary-3)",
    primary4: "var(--color-primary-4)",

    // Neutral Mains
    neutralWhite: "var(--color-neutral-white)",
    neutralBlack: "var(--color-neutral-black)",

    background: "var(--color-background)",

    pureWhite: "#ffffff",
    pureBlack: "#000000",

    // Neutral Grays
    neutral1: "var(--color-neutral-1)",
    neutral2: "var(--color-neutral-2)",
    neutral3: "var(--color-neutral-3)",

    // Neutral Overlays
    neutralOverlay: "var(--color-neutral-overlay)",

    // Notes
    docsLight: "var(--color-docs-light)",
    docsMed: "var(--color-docs-med)",
    docsDark: "var(--color-docs-dark)",

    // Flashcards
    flashcardsLight: "var(--color-flashcards-light)",
    flashcardsMed: "var(--color-flashcards-med)",

    // Assignments
    assignmentLight: "var(--color-assignment-light)",
    assignmentMed: "var(--color-assignment-med)",

    // Videos
    videoLight: "var(--color-video-light)",
    videoMed: "var(--color-video-med)",
    videoDark: "var(--color-video-dark)",

    //audio
    audio: "var(--color-audio)",

    // PDFs
    pdfLight: "var(--color-pdf-light)",
    pdfMed: "var(--color-pdf-med)",
    pdfDark: "var(--color-pdf-dark)",

    // Excel
    excelLight: "var(--color-excel-light)",
    excelMed: "var(--color-excel-med)",

    // PowerPoint
    pptLight: "var(--color-ppt-light)",
    pptMed: "var(--color-ppt-med)",

    // Icons
    icon1: "var(--color-icon-1)",
    icon2: "var(--color-icon-2)",
    icon3: "var(--color-icon-3)",
    icon4: "var(--color-icon-4)",
    icon5: "var(--color-icon-5)",

    // Font Colors
    type1: "var(--color-type-1)",
    type2: "var(--color-type-2)",
    type3: "var(--color-type-3)",
    type4: "var(--color-type-4)",

    // Subjects
    subject1: "var(--color-subject-1)",
    subject2: "var(--color-subject-2)",
    subject3: "var(--color-subject-3)",
    subject4: "var(--color-subject-4)",
    subject5: "var(--color-subject-5)",

    // Study
    mcStudyLight1: "var(--color-mc-study-1-light)",
    mcStudyMed1: "var(--color-mc-study-1-med)",
    mcStudyDark1: "var(--color-mc-study-1-dark)",

    mcStudyLight2: "var(--color-mc-study-2-light)",
    mcStudyMed2: "var(--color-mc-study-2-med)",
    mcStudyDark2: "var(--color-mc-study-2-dark)",

    mcStudyLight3: "var(--color-mc-study-3-light)",
    mcStudyMed3: "var(--color-mc-study-3-med)",
    mcStudyDark3: "var(--color-mc-study-3-dark)",

    mcStudyLight4: "var(--color-mc-study-4-light)",
    mcStudyMed4: "var(--color-mc-study-4-med)",
    mcStudyDark4: "var(--color-mc-study-4-dark)",

    mcStudyDark5: "var(--color-mc-study-5-dark)",

    // Success
    successPrimary: "var(--color-success-primary)",
    successSecondary: "var(--color-success-secondary)",

    // Error
    errorPrimary: "var(--color-error-primary)",
    errorSecondary: "var(--color-error-secondary)",

    // Gradients
    popupGradient: "var(--color-popup-gradient)",
    blueGradient: "var(--color-blue-gradient)",
    orangeGradient: "var(--color-orange-gradient)",
    purpleGradient: "var(--color-purple-gradient)",
    lightPurpleGradient: "var(--color-lightpurple-gradient)",
    redGradient: "var(--color-red-gradient)",
    psTimelineGradient: "var(--color-ps-timeline-gradient)",
    aiGradient: "var(--color-ai-gradient)",
    aiGradientFlip: "var(--color-ai-gradient-flip)",
    lightBlueGradient: "var(--color-lightblue-gradient)",

    interactiveBorder: "var(--color-interactive-border)",

    createButtonBlueHover: "var(--color-create-button-blue-hover)",
    metricsButtonDarkHover: "var(--color-metrics-button-dark-hover)",
    endScreenButtonHover: "var(--color-endscreen-button-lightblue-hover)",
    ai: "var(--color-ai)",

    // Gamification
    streakDark: "var(--color-streak-dark)",
    streakLight: "var(--color-streak-light)",
    freezeLight: "var(--color-freeze-light)",
    revivalLight: "var(--color-revival-light)",
    levelLight: "var(--color-level-light)",
    coinLight: "var(--color-coin-light)",
    disabledStreakButtonHovered: "var(--color-disabled-streak-button-hovered)",
    warningYellow: "var(--color-warning-yellow)",

    orangeBackground: "var(--color-orange-background)",
    // Plans
    plusPlan: "var(--color-plus-plan)",
    limitlessPlan: "var(--color-limitless-plan)",

    helpInstant: "var(--color-help-instant)",

    // Containers
    schemePrimary: "var(--color-scheme-primary)",
    schemeSecondary: "var(--color-scheme-secondary)",
};

export const SUBJECT_CATEGORY_COLORS = {
    Math: themeColors.subject3,
    Science: themeColors.subject5,
    Language: themeColors.subject2,
    Engineering: themeColors.subject4,
    Business: themeColors.neutral1,
    "Social Studies": themeColors.subject1,
};
