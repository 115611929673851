import { AICompletionType } from "@/graphql/schema";

export const getToolNameFromKey = (key: string) => {
    return key
        .toLowerCase()
        .replace(/-/g, " ")
        .replace(/\b\w/g, char => char.toUpperCase());
};

export const checkEmptyMandatoryInputs = (inputs, mandatoryInputs) => {
    return mandatoryInputs.some(mandatoryInput => {
        const value = inputs[mandatoryInput];
        if (typeof value === "boolean") {
            return false; // Booleans are not considered empty
        }
        if (typeof value === "number") {
            return value === null || value === undefined;
        }
        if (typeof value === "string") {
            return value.trim() === "";
        }
        if (value && typeof value === "object" && "value" in value) {
            return value.value === "" || value.value === null;
        }
        if (Array.isArray(value)) {
            return value.length === 0;
        }
        return !value;
    });
};

export const TOOL_URL = {
    [AICompletionType.LESSON_PLAN]: "ai-lesson-plan-generator",
    [AICompletionType.TEXT_PROOFREADER]: "ai-text-proofreader",
    [AICompletionType.RUBRIC_GENERATOR]: "ai-rubric-maker",
    [AICompletionType.READING_TEXT]: "ai-reading-text-generator",
    [AICompletionType.WRITING_FEEDBACK]: "ai-writing-feedback-generator",
    [AICompletionType.WORKSHEET_GENERATOR]: "ai-worksheet-generator",
    [AICompletionType.TEXT_LEVELER]: "ai-text-leveler",
    [AICompletionType.REPORT_CARD_COMMENTS]: "ai-report-card-comments-generator",
    [AICompletionType.PROFESSIONAL_EMAIL]: "ai-email-writer-for-teachers-principals",
    [AICompletionType.MAKE_IT_RELEVANT]: "make-it-relevant",
    [AICompletionType.CLASS_NEWSLETTER_GENERATOR]: "ai-newsletter-generator",
    [AICompletionType.VOCABULARY_LIST_GENERATOR]: "ai-vocabulary-list-generator",
    [AICompletionType.DISCUSSION_PROMPT_GENERATOR]: "ai-discussion-topic-generator",
    [AICompletionType.WRITING_PROMPT_GENERATOR]: "ai-writing-prompt-generator",
    [AICompletionType.MATH_WORD_PROBLEM_GENERATOR]: "ai-math-problem-generator",
    [AICompletionType.SCIENCE_LAB_GENERATOR]: "ai-science-experiment-generator-for-school",
    [AICompletionType.DEBATE_SCENARIO_GENERATOR]: "ai-debate-topic-generator",
    [AICompletionType.TEXT_PARAPHRASER]: "ai-paraphrasing-tool-for-teachers",
    [AICompletionType.ICE_BREAKER_ACTIVITIES]: "ai-ice-breaker-activities",
    [AICompletionType.CONTENT_SUMMARIZER]: "ai-content-summarizer",
    [AICompletionType.JEOPARDY_GAME_GENERATOR]: "ai-jeopardy-game-generator",
    [AICompletionType.DECODABLE_TEXT_GENERATOR]: "ai-decodable-text-generator",
    [AICompletionType.STANDARDS_UNPACKER]: "ai-standards-unpacker",
    [AICompletionType.MATH_SPIRAL_REVIEW_GENERATOR]: "ai-math-spiral-review-generator",
    [AICompletionType.JOKE_GENERATOR]: "ai-joke-generator",
    [AICompletionType.TEXT_TRANSLATOR]: "ai-text-translator",
    [AICompletionType.STORY_WORD_PROBLEMS_GENERATOR]: "ai-story-word-problems-generator",
    [AICompletionType.BIP_GENERATOR]: "ai-bip-generator",
    [AICompletionType.TIME_BASED_ACTIVITY_GENERATOR]: "ai-time-based-activity-generator",
    [AICompletionType.RECOMMENDATION_LETTER_GENERATOR]: "ai-recommendation-letter-generator",
    [AICompletionType.PERFORMANCE_TASK_GENERATOR]: "ai-performance-task-generator",
    [AICompletionType.PLC_AGENDA_GENERATOR]: "ai-plc-agenda-generator",
    [AICompletionType.SYLLABUS_GENERATOR]: "ai-syllabus-generator",
    [AICompletionType.SONG_GENERATOR]: "ai-song-generator",
};

export const TOOL_TITLE = {
    [AICompletionType.LESSON_PLAN]: "Lesson Plan",
    [AICompletionType.TEXT_PROOFREADER]: "Text Proofreader",
    [AICompletionType.RUBRIC_GENERATOR]: "Rubric Generator",
    [AICompletionType.READING_TEXT]: "Reading Text",
    [AICompletionType.WRITING_FEEDBACK]: "Writing Feedback",
    [AICompletionType.WORKSHEET_GENERATOR]: "Worksheet Generator",
    [AICompletionType.TEXT_LEVELER]: "Text Leveler",
    [AICompletionType.REPORT_CARD_COMMENTS]: "Report Card Comments",
    [AICompletionType.PROFESSIONAL_EMAIL]: "Professional Email",
    [AICompletionType.MAKE_IT_RELEVANT]: "Make It Relevant",
    [AICompletionType.CLASS_NEWSLETTER_GENERATOR]: "Class Newsletter",
    [AICompletionType.VOCABULARY_LIST_GENERATOR]: "Vocabulary List",
    [AICompletionType.DISCUSSION_PROMPT_GENERATOR]: "Discussion Prompt",
    [AICompletionType.WRITING_PROMPT_GENERATOR]: "Writing Prompt",
    [AICompletionType.MATH_WORD_PROBLEM_GENERATOR]: "Math Word Problem",
    [AICompletionType.SCIENCE_LAB_GENERATOR]: "Science Lab",
    [AICompletionType.DEBATE_SCENARIO_GENERATOR]: "Debate Scenario",
    [AICompletionType.TEXT_PARAPHRASER]: "Text Paraphraser",
    [AICompletionType.CONTENT_SUMMARIZER]: "Content Summarizer",
    [AICompletionType.JEOPARDY_GAME_GENERATOR]: "Jeopardy Game",
    [AICompletionType.DECODABLE_TEXT_GENERATOR]: "Decodable Text",
    [AICompletionType.STANDARDS_UNPACKER]: "Standards Unpacker",
    [AICompletionType.MATH_SPIRAL_REVIEW_GENERATOR]: "Math Spiral Review",
    [AICompletionType.JOKE_GENERATOR]: "Joke Generator",
    [AICompletionType.TEXT_TRANSLATOR]: "Text Translator",
    [AICompletionType.STORY_WORD_PROBLEMS_GENERATOR]: "Story Word Problems",
    [AICompletionType.BIP_GENERATOR]: "BIP",
    [AICompletionType.TIME_BASED_ACTIVITY_GENERATOR]: "Time Based Activity",
    [AICompletionType.RECOMMENDATION_LETTER_GENERATOR]: "Recommendation Letter",
    [AICompletionType.PERFORMANCE_TASK_GENERATOR]: "Performance Task",
    [AICompletionType.PLC_AGENDA_GENERATOR]: "PLC Agenda",
    [AICompletionType.SYLLABUS_GENERATOR]: "Syllabus",
};

export const aiToolOldUrlComponentToKey = (urlComponent: string): AICompletionType =>
    decodeURIComponent(urlComponent.replace(/^ai-/, "")).toUpperCase().replace(/-/g, "_") as AICompletionType;

export const getToolKeyFromUrl = url => {
    for (const [key, value] of Object.entries(TOOL_URL)) {
        if (value === url) {
            return key as AICompletionType;
        }
    }

    return null;
};
