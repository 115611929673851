import {
    Assignment,
    AssignmentSession,
    Class,
    CreateAssignmentFieldsInput,
    CreateAssignmentInput,
    CreateAssignmentSessionInput,
    UpdateAssignmentFieldsInput,
    UpdateAssignmentInput,
    UpdateAssignmentSessionInput,
} from "@/graphql/schema";
import { mutate } from "swr";
import {
    callCreateAssignment,
    callCreateAssignmentFields,
    callCreateAssignmentSession,
    callDeleteAssignment,
    callUpdateAssignment,
    callUpdateAssignmentFields,
    callUpdateAssignmentSession,
} from "./graphqlUtils";
import { safeLocalMutate, StableSWRKeys } from "../swr/swr";
import { getSWRValue } from "../swr/swr";
import { resolveClassSWRKey } from "../classes/utils";
import { platform } from "@/platform";
import { updateClass } from "../classes/utils";
import { callGetClass } from "../classes/graphqlUtils";
import { fetchBookmarks } from "../bookmarks/utils";
import { now } from "@/utils/dateTimeUtils";
import { objectWithout } from "@/utils/dataCleaning";
import { fromEntries } from "@/utils/genericUtils";
import { AssignmentSessionGroupedByUser } from "./constants";

export const resolveAssignmentsSWRKey = ({ userId, isEnabled = true }: { userId: string; isEnabled?: boolean }) => {
    return isEnabled && userId ? ["assignments", userId] : null;
};

export const resolveAssignmentSWRKey = ({
    assignmentId,
    isEnabled = true,
}: { assignmentId: string; isEnabled?: boolean }) => {
    return isEnabled && assignmentId ? ["assignment", assignmentId] : null;
};

export const resolveClassAssignmentsSWRKey = ({
    classId,
    isEnabled = true,
}: { classId: string; isEnabled?: boolean }) => {
    return isEnabled && classId ? ["class-assignments", classId] : null;
};

export const resolveFolderAssignmentsSWRKey = ({
    folderId,
    isEnabled = true,
}: { folderId: string; isEnabled?: boolean }) => {
    return isEnabled && folderId ? ["folder-assignments", folderId] : null;
};

export const resolveAssignmentSessionSWRKey = ({
    assignmentId,
    sessionId,
    isEnabled = true,
}: {
    assignmentId: string;
    sessionId: string | undefined | null;
    isEnabled?: boolean;
}) => {
    return isEnabled && assignmentId && sessionId ? ["assignmentSession", assignmentId, sessionId] : null;
};

export const resolveAssignmentSessionsSWRKey = ({
    assignmentId,
    isEnabled = true,
}: { assignmentId: string; isEnabled?: boolean }) => {
    return isEnabled && assignmentId ? ["assignmentSessions", assignmentId] : null;
};

export const updateAssignmentSession = async (session: Omit<UpdateAssignmentSessionInput, "userId">) => {
    const { assignmentId, sessionId } = session;
    callUpdateAssignmentSession({ input: session });

    await mutate(
        resolveAssignmentSessionsSWRKey({ assignmentId }),
        oldData => ({
            ...oldData,
            [sessionId]: {
                ...oldData[sessionId],
                ...session,
            },
        }),
        { revalidate: false }
    );

    return await mutate(
        resolveAssignmentSessionSWRKey({ assignmentId, sessionId }),
        oldData => ({
            ...oldData,
            session: {
                ...oldData.session,
                ...session,
            },
        }),
        { revalidate: false }
    );
};

export const updateAssignment = async (assignment: UpdateAssignmentInput) => {
    const { assignmentId } = assignment;
    callUpdateAssignment({ input: assignment });

    const updatedAssignment = await mutate(
        resolveAssignmentSWRKey({ assignmentId }),
        oldData => ({
            ...oldData,
            ...assignment,
        }),
        { revalidate: false }
    );

    mutate(
        resolveClassAssignmentsSWRKey({ classId: assignment.classId }),
        oldData => {
            if (!oldData) return undefined;

            return {
                ...oldData,
                [assignmentId]: updatedAssignment,
            };
        },
        { revalidate: false }
    );

    mutate(
        resolveFolderAssignmentsSWRKey({ folderId: assignment.folderId }),
        oldData => {
            if (!oldData) return undefined;

            return {
                ...oldData,
                [assignmentId]: updatedAssignment,
            };
        },
        { revalidate: false }
    );

    mutate(
        resolveAssignmentsSWRKey({ userId: assignment.userId }),
        oldData => {
            if (!oldData) return undefined;

            return {
                ...oldData,
                [assignmentId]: updatedAssignment,
            };
        },
        { revalidate: false }
    );

    return updatedAssignment;
};

export const createAssignmentFields = async (fields: CreateAssignmentFieldsInput) => {
    const createdAssignment = await callCreateAssignmentFields({ input: fields });

    return createdAssignment;
};

export const updateAssignmentFields = async (fields: UpdateAssignmentFieldsInput) => {
    const updatedAssignmentFields = await callUpdateAssignmentFields({ input: fields });

    return updatedAssignmentFields;
};

export const createAssignment = async ({ assignmentId, userId, ...rest }: CreateAssignmentInput) => {
    const createdAssignment = await callCreateAssignment({ input: { assignmentId, userId, ...rest } });

    return await mutate(resolveAssignmentSWRKey({ assignmentId }), createdAssignment, {
        revalidate: false,
    });
};

export const createAssignmentSession = async ({
    assignmentId,
    userId,
    ...rest
}: Omit<CreateAssignmentSessionInput, "sessionId" | "created">) => {
    const createdAssignmentSession = await callCreateAssignmentSession({ input: { assignmentId, userId, ...rest } });

    mutate(
        resolveAssignmentSessionsSWRKey({ assignmentId }),
        oldData => ({
            ...oldData,
            [createdAssignmentSession.sessionId]: createdAssignmentSession,
        }),
        { revalidate: false }
    );

    return createdAssignmentSession;
};
const saveAssignment = async ({
    assignmentId,
    userId,
    updates: _updates,
}: {
    assignmentId: string;
    userId: string;
    updates: Partial<Assignment>;
}) => {
    const updates = { ..._updates, updated: now().toString() };

    const updatedAssignment = await callUpdateAssignment({ input: { assignmentId, userId, ...updates } });
    await mutate(resolveAssignmentSWRKey({ assignmentId }), updatedAssignment, { revalidate: false });
    await mutate(
        resolveAssignmentsSWRKey({ userId }),
        oldAssignments => ({
            ...oldAssignments,
            [assignmentId]: updatedAssignment,
        }),
        { revalidate: false }
    );

    return updatedAssignment;
};

export const deleteAssignments = async ({
    assignmentIds,
}: {
    assignmentIds: {
        userId: string;
        assignmentId: string;
    }[];
}) => {
    const currentUser = await getSWRValue(StableSWRKeys.USER);
    const currentUserId = currentUser.user.ID;

    await safeLocalMutate(resolveAssignmentsSWRKey({ userId: currentUserId }), oldAssignments =>
        objectWithout(oldAssignments, ...assignmentIds.map(({ assignmentId }) => assignmentId))
    );

    await Promise.all(
        assignmentIds.map(({ assignmentId }) =>
            mutate(resolveAssignmentSWRKey({ assignmentId }), undefined, { revalidate: false })
        )
    );

    try {
        await Promise.all(
            assignmentIds.map(({ userId, assignmentId }) => callDeleteAssignment({ assignmentId, userId }))
        );
    } catch {
        const toast = await platform.toast();
        toast.error("Failed to delete flashcard sets. Please refresh the page.");
    }
};

export const moveAssignments = async ({
    assignmentIds,
    sourceFolderId = null,
    destinationFolderId,
    sourceClassId,
    destinationClassId,
    isPublic,
    sharedSections,
}: {
    assignmentIds: {
        userId: string;
        assignmentId: string;
    }[];
    sourceFolderId: string | null | undefined;
    destinationFolderId: string | null | undefined;
    sourceClassId?: string | null | undefined;
    destinationClassId?: string | null | undefined;
    isPublic?: boolean;
    sharedSections?: string[] | null;
}) => {
    await genericUpdateAssignments({
        assignmentIds,
        sourceFolderId,
        destinationFolderId,
        sourceClassId,
        destinationClassId,
        updatedFields: {
            folderId: destinationFolderId,
            classId: destinationClassId,
            sections: sharedSections,
            ...(isPublic === undefined ? {} : { public: isPublic }),
            ...(sourceClassId !== destinationClassId ? { addedAt: now().toString() } : {}),
            ...(destinationClassId ? { password: null } : {}),
        },
    });
};

export const genericUpdateAssignments = async ({
    assignmentIds,
    sourceFolderId,
    destinationFolderId,
    sourceClassId,
    destinationClassId,
    updatedFields,
}: {
    assignmentIds: {
        userId: string;
        assignmentId: string;
    }[];
    sourceFolderId?: string | null;
    destinationFolderId?: string | null;
    sourceClassId?: string | null;
    destinationClassId?: string | null;
    updatedFields: Partial<Assignment>;
}) => {
    const currentUser = await getSWRValue(StableSWRKeys.USER);
    const currentUserId = currentUser.user.ID;

    await Promise.all([
        // mutate the source folder cache for faster UX
        mutate(
            resolveFolderAssignmentsSWRKey({ folderId: sourceFolderId }),
            async oldAssignments =>
                fromEntries(
                    Object.values(
                        updateCache(
                            oldAssignments,
                            assignmentIds.map(({ assignmentId }) => assignmentId),
                            updatedFields
                        )
                    )
                        .filter(assignment => assignment.folderId === sourceFolderId)
                        .map(assignment => [assignment.assignmentId, assignment])
                ),
            { revalidate: false }
        ),
        mutate(
            resolveClassAssignmentsSWRKey({ classId: sourceClassId }),
            async oldAssignments =>
                updateCache(
                    oldAssignments,
                    assignmentIds.map(({ assignmentId }) => assignmentId),
                    updatedFields
                ),
            { revalidate: false }
        ),
        ...assignmentIds.map(({ assignmentId }) =>
            mutate(
                resolveAssignmentSWRKey({ assignmentId }),
                async oldAssignment => (oldAssignment ? { ...oldAssignment, ...updatedFields } : null),
                { revalidate: false }
            )
        ),
    ]);

    try {
        const updatedAssignments = await Promise.all(
            assignmentIds.map(({ userId, assignmentId }) =>
                saveAssignment({ assignmentId, userId, updates: updatedFields })
            )
        );

        if (destinationFolderId !== sourceFolderId) {
            await safeLocalMutate(resolveFolderAssignmentsSWRKey({ folderId: destinationFolderId }), oldAssignments =>
                addToCache(oldAssignments, updatedAssignments)
            );
        }

        if (destinationClassId !== sourceClassId) {
            await safeLocalMutate(resolveClassAssignmentsSWRKey({ classId: destinationClassId }), oldAssignments =>
                addToCache(oldAssignments, updatedAssignments)
            );

            if (sourceClassId) {
                const sourceClass =
                    (await mutate(resolveClassSWRKey({ classId: sourceClassId }), (oldData: Class) => oldData, {
                        revalidate: false,
                    })) ?? (await callGetClass({ classId: sourceClassId }));

                const updatedPinned = sourceClass.pinned.filter(
                    itemId => !assignmentIds.find(({ assignmentId }) => itemId === assignmentId)
                );

                if (updatedPinned.length !== sourceClass.pinned.length) {
                    await updateClass({ classId: sourceClassId, pinned: updatedPinned }, { ID: currentUserId });
                }
            }

            if (destinationClassId) {
                const bookmarks = await fetchBookmarks({ userId: currentUserId });

                const bookmarkedAssignments = assignmentIds
                    .filter(({ assignmentId }) => bookmarks.some(b => b.ID === assignmentId))
                    .map(({ assignmentId }) => assignmentId);

                if (bookmarkedAssignments.length) {
                    const destinationClass =
                        (await mutate(
                            resolveClassSWRKey({ classId: destinationClassId }),
                            (oldData: Class) => oldData,
                            {
                                revalidate: false,
                            }
                        )) ?? (await callGetClass({ classId: destinationClassId }));

                    const updatedPinned = [...destinationClass.pinned, ...bookmarkedAssignments];
                    await updateClass({ classId: destinationClassId, pinned: updatedPinned }, { ID: currentUserId });
                }
            }
        }
    } catch {
        const toast = await platform.toast();
        toast.error("Failed to update assignments. Please refresh the page.");
    }
};

const updateCache = (
    cache: Record<string, Assignment>,
    assignmentIds: string[],
    updatedFields: Partial<Assignment>
) => {
    const newCache = { ...cache };
    // biome-ignore lint/complexity/noForEach: <explanation>
    // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
    assignmentIds.forEach(assignmentId => (newCache[assignmentId] = { ...newCache[assignmentId], ...updatedFields }));
    return newCache;
};

const addToCache = (cache: Record<string, Assignment>, assignments: Assignment[]) => {
    const newCache = { ...cache };
    // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
    // biome-ignore lint/complexity/noForEach: <explanation>
    assignments.forEach(assignment => (newCache[assignment.assignmentId] = assignment));
    return newCache;
};

export const trashAssignments = async ({
    assignmentIds,
    sourceFolderId,
    sourceClassId,
}: {
    assignmentIds: {
        userId: string;
        assignmentId: string;
    }[];
    sourceFolderId: string | null;
    sourceClassId?: string | null;
    destinationClassId?: string | null;
}) => {
    await updateAssignmentsTrashState({
        assignmentIds,
        sourceFolderId,
        sourceClassId,
        inTrash: true,
        removeFromFolder: true,
        removeFromClass: true,
    });
};

export const restoreAssignments = async ({
    assignmentIds,
    sourceFolderId,
}: {
    assignmentIds: {
        userId: string;
        assignmentId: string;
    }[];
    sourceFolderId: string | null;
}) => {
    await updateAssignmentsTrashState({
        assignmentIds,
        sourceFolderId,
        inTrash: false,
        removeFromFolder: true,
    });
};

const updateAssignmentsTrashState = async ({
    assignmentIds,
    sourceFolderId,
    sourceClassId,
    inTrash,
    removeFromFolder,
    removeFromClass,
}: {
    assignmentIds: {
        userId: string;
        assignmentId: string;
    }[];
    sourceFolderId: string | null;
    sourceClassId?: string | null;
    inTrash: boolean;
    removeFromFolder: boolean;
    removeFromClass?: boolean;
}) => {
    await genericUpdateAssignments({
        assignmentIds,
        sourceFolderId,
        // TODO: why destinationFolderId is set to sourceFolderId ?
        destinationFolderId: removeFromFolder ? null : sourceFolderId,
        sourceClassId,
        updatedFields: {
            trash: inTrash,
            ...(removeFromClass && { classId: null }),
            ...(removeFromFolder && { folderId: null }),
        },
    });
};

export const groupAssignmentSessionsByUser = (
    assignmentSessions?: AssignmentSession[] | null
): AssignmentSessionGroupedByUser => {
    return (
        assignmentSessions?.reduce((acc, session) => {
            acc[session.userId] = [...(acc[session.userId] || []), session];
            return acc;
        }, {} as AssignmentSessionGroupedByUser) ?? {}
    );
};
