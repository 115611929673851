import { FileType } from "@knowt/syncing/graphql/schema";
export const AV_BUCKET = "knowt-video-streaming-prod";
export const PDF_BUCKET = "knowt-pdf-flow-prod";

export const PPT_FORMATS = [FileType.PPT, FileType.PPTX];
export const EXCEL_FORMATS = [FileType.XLS, FileType.XLSX];
export const PDF_FORMATS = [FileType.PDF];

export const VIDEO_FORMATS = [FileType.MP4, FileType.MOV, FileType.MKV];
//TODO: webm moved to audio temporarily so live lecture show audio
export const AUDIO_FORMATS = [FileType.M4A, FileType.MP3, FileType.WAV, FileType.WEBM];
