import { VDocumentFragment, VHTMLDocument, parseHTML } from "zeed-dom";
import { getHtmlFromContent } from "./getHtmlFromContent";

export const getPlainTextFromContent = ({
    content,
}: {
    content: string;
    type?: "note" | "flashcard";
}): string => {
    const htmlInput = getHtmlFromContent({ content });
    return htmlToPlainText(htmlInput);
};

const htmlToPlainText = (html: string) => {
    const dom = parseHTML(html);
    try {
        return getRecursiveNestedContent(dom);
    } catch (e) {
        // biome-ignore lint: noConsole
        console.error("HTML PARSING ERROR", e);
        return dom.textContent ?? "";
    }
};

export const getRecursiveNestedContent = (
    node: Node | VHTMLDocument | VDocumentFragment,
    context: { nestingLevel?: number } = { nestingLevel: 0 }
): string => {
    const children = Array.from(node.childNodes);
    const element = node as Element;
    const tagName = element.tagName;

    if (children.length === 0) {
        if (tagName === "BR") return "\n";
        return node.textContent ?? "";
    }

    // handle nested ordered lists - add ordered list prefix and indentations
    if (tagName === "OL") {
        const currentNestingLevel = context.nestingLevel || 0;
        const nextContext = { ...context, nestingLevel: currentNestingLevel + 1 };

        const start = Number.parseInt(element.getAttribute("start")) || 1;
        const listItems = children
            .map((child, index) => {
                if ((child as Element).tagName === "LI") {
                    const indent = " ".repeat(currentNestingLevel * 2);
                    const childContent = getRecursiveNestedContent(child, nextContext);
                    return childContent ? `${indent}${start + index}. ${childContent}` : "";
                }
                return getRecursiveNestedContent(child, nextContext);
            })
            .filter(text => text.trim() !== "")
            .join("\n");

        return listItems;
    }

    // Handle list items - process their content without adding prefixes (prefix is handled by parent OL/UL)
    if (tagName === "LI") {
        // Special handling to prevent double-line breaks between nested lists inside list items
        const processedContent = children.map(child => getRecursiveNestedContent(child, context)).join("");
        return processedContent;
    }

    const needsNewLine =
        tagName !== "P" &&
        tagName !== "H1" &&
        tagName !== "H2" &&
        tagName !== "H3" &&
        tagName !== "H4" &&
        tagName !== "H5" &&
        tagName !== "H6" &&
        tagName !== "BR";

    return children.map(child => getRecursiveNestedContent(child, context)).join(needsNewLine ? "\n" : "");
};
