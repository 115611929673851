import {
    getRawFlashcardSet,
    listFlashcardSetByUserNoContent,
    listFlashcardSetsByClassNoContent,
    listFlashcardSetsByFolderNoContent,
    listRawFlashcardsByFlashcardSet,
} from "@/graphql/customQueries";
import { RawFlashcard, RawFlashcardSet } from "@/graphql/customSchema";
import {
    batchUpdateFlashcard,
    createFlashcardSetV2,
    deleteAudio,
    deleteFlashcardSet,
    detectLanguage,
    migrateFlashcardSet,
    textToSpeech,
    updateFlashcardSetV2,
} from "@/graphql/mutations";
import { callGetFolder } from "@/hooks/folders/graphqlUtils";
import { handleMaybeProgressing } from "@/hooks/gamification/monitoring/progressing";
import { callGetMedia } from "@/hooks/media/graphqlUtils";
import { fetchNoteMetadata } from "@/hooks/notes/graphqlUtils";
import { platform } from "@/platform";
import { CACHE_ENV, SHOULD_PERSIST_CACHE } from "@/utils/client/configs";
import { ServerClientWithCookies, client, listData, listGroupedData } from "@/utils/client/graphql";
import { deepScrapeEmptyFields, objectWithout, pick, scrapeEmptyFields } from "@/utils/dataCleaning";
import { TIME_SECONDS, now } from "@/utils/dateTimeUtils";
import { delay, fromEntries, retry } from "@/utils/genericUtils";
import { listFlashcardsByFlashcardSet } from "@knowt/syncing/graphql/queries";
import { DeleteFlashcardSetInput, Flashcard, FlashcardSet, TextToSpeechInput } from "@knowt/syncing/graphql/schema";
import { memoize } from "nextjs-better-unstable-cache";

export const callListStandaloneFlashcardSetByUserNoContent = async ({
    ignoreTrashed = false,
    userId,
    serverClient,
}: {
    ignoreTrashed?: boolean;
    userId?: string;
    serverClient?: ServerClientWithCookies;
}) => {
    if (!userId) return undefined;

    const allFlashcardSets = (await listGroupedData({
        listQuery: listFlashcardSetByUserNoContent,
        queryName: "listFlashcardSetByUser",
        input: { userId },
        groupingKey: "flashcardSetId",
        ignoreTrashed,
        serverClient,
    })) as Record<string, FlashcardSet>;

    return fromEntries(Object.entries(allFlashcardSets).filter(([, { noteId }]) => !noteId));
};

export const callListFlashcardSetsByFolder = async ({
    folderId,
    password,
    serverClient,
}: {
    folderId: string;
    password?: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        // TODO: swap this so that we're not fetching the whole list of flashcards
        listQuery: listFlashcardSetsByFolderNoContent,
        groupingKey: "flashcardSetId",
        input: { folderId, password },
        queryName: "listFlashcardSetsByFolder",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, FlashcardSet>;
};

export const callListFlashcardSetsByClass = async ({
    classId,
    serverClient,
}: {
    classId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        // TODO: swap this so that we're not fetching the whole list of flashcards
        listQuery: listFlashcardSetsByClassNoContent,
        groupingKey: "flashcardSetId",
        input: { classId },
        queryName: "listFlashcardSetsByClass",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, FlashcardSet>;
};

export const fetchFlashcardSet = async ({
    flashcardSetId,
    serverClient,
    password,
    raw = false,
}: {
    flashcardSetId?: string;
    password?: string;
    serverClient?: ServerClientWithCookies;
    raw?: boolean;
}) => {
    const params = { flashcardSetId, serverClient, password, raw };
    // TODO: temporarily disabling memoization
    // return EXAM_FLASHCARD_SETS.includes(flashcardSetId)
    //     ? await memoizedFetchFlashcardSet(omit(params, "serverClient"))
    //     : await unmemoizedFetchFlashcardSet(params);
    return await unmemoizedFetchFlashcardSet(params);
};

const unmemoizedFetchFlashcardSet = async ({
    flashcardSetId,
    serverClient,
    password,
    raw = false,
}: {
    flashcardSetId?: string;
    password?: string;
    serverClient?: ServerClientWithCookies;
    raw?: boolean;
}) => {
    const [rawFlashcardSet, flashcardsData] = await Promise.all([
        callGetRawFlashcardSet({ flashcardSetId, serverClient, password }),
        !raw
            ? callListFlashcardsByFlashcardSet({
                  flashcardSetId,
                  serverClient,
              })
            : null,
    ]);

    /**
     * rawFlashcardSet can be `null` if it's a 404 `flashcardSetId`
     */
    if (!rawFlashcardSet) return null;

    /**
     * If a flashcardSet have flashcards null, it's because
     * we're trying to fetch a flashcardSet you are
     * not authorized to see (password, private, etc.)
     */
    if (!rawFlashcardSet.flashcards) return rawFlashcardSet;

    if (!serverClient && isLegacyFlashcardSet(rawFlashcardSet)) {
        // we can migrate in the background, but awaiting the migration
        // seems safer, cuz that way, we only give the set to the caller
        // once it's migrated on the backend, then the caller can safely
        // use it assuming it's migrated (adding/removing flashcards, etc)
        await callMigrateFlashcardSet({ flashcardSetId });
    }

    return flashcardSetWithFlashcardsData(rawFlashcardSet, flashcardsData);
};

const _memoizedFetchFlashcardSet = memoize(unmemoizedFetchFlashcardSet, {
    persist: SHOULD_PERSIST_CACHE,
    duration: TIME_SECONDS.WEEK,
    revalidateTags: ({ flashcardSetId, raw, password }) => [
        CACHE_ENV,
        "get-flashcard-set",
        flashcardSetId,
        `raw:${raw}`,
        `password:${password}`,
    ],
    logid: "get-flashcard-set",
});

const isLegacyFlashcardSet = flashcardSet => {
    return flashcardSet.flashcards.some(({ term, definition }) => !!term || !!definition);
};

export const callGetRawFlashcardSet = async ({
    flashcardSetId,
    serverClient,
    password,
}: {
    flashcardSetId: string;
    serverClient?: ServerClientWithCookies;
    password?: string;
}) => {
    const input = { flashcardSetId, password };

    return client
        .query({
            query: getRawFlashcardSet,
            variables: { input },
            serverClient,
        })
        .then(({ data }) => data.getFlashcardSet)
        .catch(async error => {
            const { report } = await platform.analytics.logging();
            report(error, "getFlashcardSet", input);
            throw error;
        });
};

export const callGetRawFlashcardSets = async (flashcardSetIds: string[]): Promise<Record<string, RawFlashcardSet>> => {
    return fromEntries(
        (await Promise.all(flashcardSetIds.map(flashcardSetId => callGetRawFlashcardSet({ flashcardSetId }))))
            .filter(Boolean)
            .map(flashcardSet => [flashcardSet.flashcardSetId, flashcardSet])
    );
};

export const callListFlashcardsByFlashcardSet = async ({
    flashcardSetId,
    serverClient,
}: {
    flashcardSetId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    const input = scrapeEmptyFields({ flashcardSetId });

    return (await listGroupedData({
        listQuery: listFlashcardsByFlashcardSet,
        groupingKey: "flashcardId",
        input,
        queryName: "listFlashcardsByFlashcardSet",
        ignoreTrashed: false,
        serverClient,
    }).catch(async error => {
        const { report } = await platform.analytics.logging();
        report(error, "listFlashcardsByFlashcardSet", input);
        throw error;
    })) as Record<string, Flashcard>;
};

export const callListRawFlashcardsByFlashcardSet = async ({
    flashcardSetId,
    serverClient,
}: {
    flashcardSetId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    const input = scrapeEmptyFields({ flashcardSetId });

    return (await listData({
        listQuery: listRawFlashcardsByFlashcardSet,
        input,
        queryName: "listFlashcardsByFlashcardSet",
        ignoreTrashed: false,
        serverClient,
    }).catch(async error => {
        const { report } = await platform.analytics.logging();
        report(error, "listRawFlashcardsByFlashcardSet", input);
        throw error;
    })) as RawFlashcard[];
};

export const flashcardSetWithFlashcardsData = (rawFlashcardSet, flashcardsData): FlashcardSet => {
    if (!rawFlashcardSet) return null;
    if (!flashcardsData) return rawFlashcardSet;

    return { ...rawFlashcardSet, flashcards: mergeFlashcards(rawFlashcardSet.flashcards, flashcardsData) };
};

export const mergeFlashcards = (initialFlashcardsData, additionalFlashcardsDataObj) => {
    if (!initialFlashcardsData) return null;

    return initialFlashcardsData.map(initialData => {
        const additionalData = additionalFlashcardsDataObj[initialData.flashcardId] ?? {};
        return {
            ...initialData,
            ...scrapeEmptyFields(
                pick(
                    additionalData,
                    "term",
                    "termAudio",
                    "definition",
                    "definitionAudio",
                    "image",
                    "secondaryImage",
                    "flashcardSetId",
                    "userId",
                    "isCorrect",
                    "questionType",
                    "distractors",
                    "distractorIds",
                    "schedule",
                    "state",
                    "created",
                    "updated",
                    "disabled",
                    "termRecordedAudio",
                    "definitionRecordedAudio",
                    "mcOptions",
                    "termCustomHint",
                    "definitionCustomHint"
                )
            ),
        };
    });
};

export const callUpdateFlashcardSet = async (flashcardSetId: string, updatedFields: Partial<FlashcardSet>) => {
    const input = cleanFlashcardSetUpdateInput({
        flashcardSetId,
        ...objectWithout(
            {
                updated: now(),
                ...updatedFields,
            },
            "textbookId",
            "chapterId",
            "isBookmarked"
        ),
        ...(updatedFields?.flashcards && { size: updatedFields?.flashcards?.length }),
    });

    return await retry(async () => {
        try {
            const { data } = await client.mutate({
                mutation: updateFlashcardSetV2,
                variables: { input },
            });

            // wait for the flashcard set to be navigated to
            delay(() => handleMaybeProgressing(data.updateFlashcardSetV2), 3_000);

            return data.updateFlashcardSetV2.item as FlashcardSet;
        } catch (error) {
            const { report } = await platform.analytics.logging();
            report(error, "updateFlashcardSet", input);
            throw error;
        }
    });
};

const cleanFlashcardSetUpdateInput = input =>
    deepScrapeEmptyFields(objectWithout(input, "views", "rating", "ratingCount"), [
        "topic",
        "subject",
        "exam",
        "examUnit",
        "exam_v2",
        "examSection",
        "schoolId",
        "folderId",
        "classId",
        "grade",
        "password",
        "sections",
    ]);

export const callBatchUpdateFlashcard = async (
    {
        userId,
        flashcardSetId,
        items,
    }: {
        userId: string;
        flashcardSetId: string;
        items: Flashcard[];
    },
    overrideClient?: any
) => {
    const input = {
        userId,
        items: items.map(flashcard => ({ ...deepScrapeEmptyFields(flashcard), flashcardSetId })),
    };

    return await retry(
        async () =>
            await (overrideClient ?? client)
                .mutate({
                    mutation: batchUpdateFlashcard,
                    variables: { input },
                })
                .then(({ data }) => data.batchUpdateFlashcard.items)
                .catch(async error => {
                    const { report } = await platform.analytics.logging();
                    report(error, "batchUpdateFlashcard", {
                        userId,
                        stringifiedItems: JSON.stringify(input.items),
                    });
                    throw error;
                })
    );
};

export const callMigrateFlashcardSet = async ({ flashcardSetId }: { flashcardSetId: string }) => {
    const input = { flashcardSetId };

    return retry(async () =>
        client
            .mutate({
                mutation: migrateFlashcardSet,
                variables: { input },
            })
            .then(({ data }) => data.migrateFlashcardSet)
            .catch(async error => {
                const { report } = await platform.analytics.logging();
                report(error, "migrateFlashcardSet", input);
                throw error;
            })
    );
};

export const callDeleteFlashcardSet = async ({ flashcardSetId, userId }: DeleteFlashcardSetInput) => {
    const input = { flashcardSetId, userId };

    return await retry(() =>
        client
            .mutate({
                mutation: deleteFlashcardSet,
                variables: { input },
            })
            .catch(async error => {
                const { report } = await platform.analytics.logging();
                report(error, "deleteFlashcardSet", input);
                throw error;
            })
    );
};

export const callDetectLanguage = async ({ text, flashcardSetId, flashcardId, side }: TextToSpeechInput) => {
    const input = { text, flashcardSetId, flashcardId, side };
    return await client
        .mutate({
            mutation: detectLanguage,
            variables: { input },
        })
        .then(({ data }) => data.detectLanguage)
        .catch(async error => {
            const { report } = await platform.analytics.logging();
            report(error, "detectLanguage", input);
            throw error;
        });
};

export const callTextToSpeech = async ({ text, flashcardSetId, flashcardId, side, voice }: TextToSpeechInput) => {
    const input = { text, flashcardSetId, flashcardId, side, voice };
    return await client
        .mutate({
            mutation: textToSpeech,
            variables: { input },
        })
        .then(({ data }) => data.textToSpeech)
        .catch(async error => {
            const { report } = await platform.analytics.logging();
            report(error, "textToSpeech", input);
            throw error;
        });
};

export const callDeleteAudio = async ({ text = "", flashcardSetId, flashcardId, side }: TextToSpeechInput) => {
    const input = { text, flashcardSetId, flashcardId, side };
    return await client
        .mutate({
            mutation: deleteAudio,
            variables: { input },
        })
        .then(({ data }) => data.deleteAudio)
        .catch(async error => {
            const { report } = await platform.analytics.logging();
            report(error, "deleteAudio", input);
            throw error;
        });
};

export const callCreateFlashcardSet = async (initialFields: FlashcardSet) => {
    const privacySettings = await getPrivacySettings(initialFields);
    const input = { ...initialFields, ...privacySettings };

    try {
        const { data } = await client.mutate({
            mutation: createFlashcardSetV2,
            variables: { input },
        });

        // wait for the flashcard set to be navigated to
        delay(() => handleMaybeProgressing(data.createFlashcardSetV2), 3_000);

        return data.createFlashcardSetV2.item as FlashcardSet;
    } catch (error) {
        const { report } = await platform.analytics.logging();
        report(error, "callCreateFlashcardSet", { input });
        throw error;
    }
};

const getPrivacySettings = async (input: Partial<FlashcardSet>): Promise<Pick<FlashcardSet, "public" | "password">> => {
    if (input.password !== undefined) return { public: false, password: input.password };
    if (typeof input.public === "boolean") return { public: input.public, password: null };
    if (input.mediaId) {
        const media = await callGetMedia({ mediaId: input.mediaId });
        return { public: media.public, password: media.password };
    }
    if (input.noteId) {
        const noteMetadata = await fetchNoteMetadata({ noteId: input.noteId });
        return { public: noteMetadata.public, password: noteMetadata.password };
    }
    if (input.folderId) {
        const parentFolder = await callGetFolder({ folderId: input.folderId });
        return { public: parentFolder.public, password: parentFolder.password };
    }
    return { public: true, password: null };
};
