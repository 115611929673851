import { createClassV2, deleteClass, joinClassV2, leaveClass, updateClass } from "@/graphql/mutations";
import { getClass, listClasses } from "@/graphql/queries";
import { client, listGroupedData, ServerClientWithCookies } from "@/utils/client/graphql";
import { retry } from "@/utils/genericUtils";
import { Class, ClassPermission, ClassRole, CreateClassInput, UpdateClassInput } from "@knowt/syncing/graphql/schema";

import { handleMaybeProgressing } from "@/hooks/gamification/monitoring/progressing";
import { now } from "@/utils/dateTimeUtils";

export const callCreateClass = async (initialFields: CreateClassInput) => {
    const input = { ...initialFields };

    const result = await client
        .mutate({
            mutation: createClassV2,
            variables: { input: input },
        })
        .then(({ data }) => data.createClassV2);

    await handleMaybeProgressing(result);

    return result.item;
};

export const callUpdateClass = async (initialFields: Omit<UpdateClassInput, "updated">) => {
    const course = await retry(async () => {
        const input = { updated: now().toString(), ...initialFields };

        return client
            .mutate({
                mutation: updateClass,
                variables: { input: input },
            })
            .then(({ data }) => data.updateClass);
    });

    return course;
};

export const callGetClass = async ({
    classId,
    serverClient,
}: {
    classId: string | null | undefined;
    serverClient?: ServerClientWithCookies;
}) => {
    if (!classId) return null;

    return await client
        .query({
            query: getClass,
            variables: {
                input: {
                    classId: classId,
                },
            },
            serverClient,
        })
        .then(({ data }) => data.getClass);
};

export const callListClasses = async ({ serverClient }: { serverClient?: ServerClientWithCookies }) => {
    return (await listGroupedData({
        listQuery: listClasses,
        groupingKey: "classId",
        input: {},
        queryName: "listClasses",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, Class>;
};

export const callJoinClass = async ({
    classId,
    sections,
    serverClient,
}: {
    classId: string;
    sections: string[];
    serverClient?: ServerClientWithCookies;
}) => {
    const result = await client
        .mutate({
            mutation: joinClassV2,
            variables: {
                input: { classId, sections },
            },
            serverClient,
        })
        .then(({ data }) => data.joinClassV2);

    await handleMaybeProgressing(result);

    return result.item;
};

export const callLeaveClass = async ({
    classId,
    serverClient,
}: {
    classId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return client
        .mutate({
            mutation: leaveClass,
            variables: {
                input: { classId },
            },
            serverClient,
        })
        .then(({ data }) => data.leaveClass);
};

export const callDeleteClass = async ({
    classId,
    serverClient,
}: {
    classId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return client
        .mutate({
            mutation: deleteClass,
            variables: {
                input: { classId },
            },
            serverClient,
        })
        .then(({ data }) => data.deleteClass);
};

export const getClassPermission = ({ course, userId }: { course: Class; userId: string }): ClassPermission => {
    if (!userId || !course) {
        return ClassPermission.NONE;
    }

    if (course.userId === userId) {
        return ClassPermission.ALL;
    }

    const memberEntry = course.members.find(member => member.userId === userId);

    if (memberEntry?.role === ClassRole.CO_OWNER) {
        return ClassPermission.ALL;
    } else if (memberEntry?.role === ClassRole.CONTRIBUTOR) {
        return ClassPermission.ONLY_OWN;
    } else if (memberEntry?.role === ClassRole.VIEWER) {
        return ClassPermission.VIEW;
    }

    return ClassPermission.NONE;
};

export const getClassRole = (course: Class | null | undefined, userId: string | null | undefined): ClassRole => {
    if (course?.userId === userId) {
        return ClassRole.OWNER;
    }

    const memberEntry = course?.members?.find(member => member?.userId === userId)?.role;

    return memberEntry || ClassRole.STUDENT;
};
