import { callListFlashcardSetsByFolder } from "@/hooks/flashcards/graphqlUtils";
import {
    callCreateFolder,
    callDeleteFolder,
    callGetFolder,
    callListFoldersByParent,
    callListFoldersByUser,
    callUpdateFolder,
} from "@/hooks/folders/graphqlUtils";
import { StableSWRKeys, getSWRValue, safeLocalMutate } from "@/hooks/swr/swr";
import { objectWithout } from "@/utils/dataCleaning";
import { Assignment, FlashcardSet, Folder, Media, Note, UserDetails } from "@knowt/syncing/graphql/schema";
import { mutate } from "swr";
import { v4 as uuidv4 } from "uuid";
import {
    deleteFlashcardSets,
    duplicateFlashcardSets,
    genericUpdateFlashcardSets,
    resolveFolderFlashcardSetsSWRKey,
    updateFlashcardSetsPublicState,
} from "../flashcards/utils";
import { callListNotesByFolder } from "../notes/graphqlUtils";
import {
    deleteNotes,
    duplicateNotes,
    genericUpdateNotes,
    resolveFolderNotesSWRKey,
    updateNotesPublicState,
} from "../notes/utils";

import { platform } from "@/platform";
import { assertTruthy } from "@/utils/assertions";
import { callListMediaByFolder } from "../media/graphqlUtils";
import { deleteMedias, duplicateMedias, genericUpdateMedias, resolveFolderMediasSWRKey } from "../media/utils";

import { now } from "@/utils/dateTimeUtils";
import { fromEntries } from "@/utils/genericUtils";
import { genericUpdateAssignments, resolveFolderAssignmentsSWRKey } from "../assignments/utils";
import { callListAssignmentsByFolder } from "../assignments/graphqlUtils";

type FoldersMap = Record<string, Folder>;

export const moveFolder = async ({
    folderId,
    userId,
    sourceFolderId = null,
    parentId,
    sourceClassId = null,
    destinationClassId,
    disableToast,
    isPublic,
    sharedSections,
}: {
    folderId: string;
    userId: string | undefined;
    sourceFolderId: string | null;
    parentId: string | null | undefined;
    sourceClassId?: string | null | undefined;
    destinationClassId?: string | null | undefined;
    disableToast?: boolean;
    isPublic?: boolean;
    sharedSections?: string[] | null;
}) => {
    const updates = {
        parentId,
        sections: sharedSections,
        ...(sourceClassId !== destinationClassId ? { classId: destinationClassId, addedAt: now().toString() } : {}),
        ...(isPublic === undefined ? {} : { public: isPublic }),
        ...(destinationClassId ? { password: null } : {}),
    };

    // ensure the `folder` object is in swr cache
    let cachedFolder = await mutate(resolveFolderSWRKey({ folderId }), current => current, { revalidate: false });
    if (!cachedFolder) {
        cachedFolder = await mutate(resolveFolderSWRKey({ folderId }), callGetFolder({ folderId }), {
            revalidate: false,
        });
    }

    assertTruthy(cachedFolder, "folder variable is empty");

    const swrKeysToUpdates = [
        resolveFolderSWRKey({ folderId }),
        resolveFoldersSWRKey({ userId }),
        resolveClassFoldersSWRKey({ classId: sourceClassId }),
        resolveNestedFoldersSWRKey({ parentId }),
        resolveClassFoldersSWRKey({ classId: destinationClassId }),
    ];

    await safeLocalMutate(resolveNestedFoldersSWRKey({ parentId: sourceFolderId }), (oldFolders: FoldersMap) =>
        fromEntries(
            Object.values({
                ...oldFolders,
                [folderId]: { ...cachedFolder, ...updates },
            })
                .filter(({ parentId }) => parentId === sourceFolderId)
                .map(folder => [folder.folderId, folder])
        )
    );

    await Promise.all(
        swrKeysToUpdates.map(swrKey =>
            safeLocalMutate(swrKey, (oldFolders: FoldersMap) => ({
                ...oldFolders,
                [folderId]: { ...cachedFolder, ...updates },
            }))
        )
    );

    if (destinationClassId === sourceClassId) {
        await callUpdateFolder(folderId, { userId, ...updates });
    } else {
        // we need to propagate the `classId: destinationClassId` update to all nested items
        await updateFolderAndNestedItemsFields({
            folderId,
            userId,
            parentId,
            topLevelFolderUpdates: updates,
            nestedFoldersUpdates: updates,
            itemsUpdates: objectWithout(updates, "parentId"),
        });
    }

    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Folder - Moved to Folder", { folderId });

    if (disableToast) {
        const toast = await platform.toast();
        toast.success("Folder moved!");
    }
};

export const moveFolders = async ({
    folderIds,
    sourceFolderId,
    parentId,
    sourceClassId,
    destinationClassId,
    disableToast,
    sharedSections,
}: {
    folderIds: {
        userId: string;
        folderId: string;
    }[];
    sourceFolderId: string | null;
    parentId: string | null | undefined;
    sourceClassId?: string | null | undefined;
    destinationClassId?: string | null | undefined;
    disableToast?: boolean;
    sharedSections?: string[] | null;
}) => {
    const currentUser = await getSWRValue(StableSWRKeys.USER);
    const currentUserId = currentUser.user.ID;

    const swrKeysToUpdates = [
        resolveFoldersSWRKey({ userId: currentUserId }),
        resolveNestedFoldersSWRKey({ parentId: sourceFolderId }),
        resolveClassFoldersSWRKey({ classId: sourceClassId }),
        resolveNestedFoldersSWRKey({ parentId }),
        resolveClassFoldersSWRKey({ classId: destinationClassId }),
    ];

    await Promise.all(
        folderIds.map(({ userId, folderId }) =>
            moveFolder({
                folderId,
                userId,
                sourceFolderId,
                parentId,
                sourceClassId,
                destinationClassId,
                disableToast,
                sharedSections,
            })
        )
    );

    // extra cache updater outside of the moveFolder function to fix individual cache update conflicts
    await Promise.all(
        swrKeysToUpdates.map(swrKey =>
            safeLocalMutate(swrKey, (oldFolders: FoldersMap) => ({
                ...oldFolders,
                ...folderIds.reduce((acc, { folderId }) => {
                    acc[folderId] = {
                        ...oldFolders[folderId],
                        parentId,
                        classId: destinationClassId,
                        sections: sharedSections,
                    };
                    return acc;
                }, {}),
            }))
        )
    );
};

export const renameFolder = async ({
    folderId,
    userId,
    newName,
    classId,
}: {
    folderId: string;
    userId: string;
    newName: string;
    classId?: string;
}) => {
    const newFolders = await safeLocalMutate(
        classId ? resolveClassFoldersSWRKey({ classId }) : resolveFoldersSWRKey({ userId }),
        async (oldFolders: FoldersMap) => {
            if (!oldFolders?.[folderId]) {
                return oldFolders;
            }

            return {
                ...oldFolders,
                [folderId]: { ...oldFolders[folderId], name: newName },
            };
        }
    );

    if (newFolders?.[folderId]?.parentId) {
        await safeLocalMutate(
            resolveNestedFoldersSWRKey({
                parentId: newFolders[folderId].parentId,
            }),
            async (oldFolders: FoldersMap) => {
                if (!oldFolders?.[folderId]) {
                    return oldFolders;
                }

                return {
                    ...oldFolders,
                    [folderId]: { ...oldFolders[folderId], name: newName },
                };
            }
        );
    }
    const updatedFolder = await callUpdateFolder(folderId, { userId, name: newName });
    await mutate(resolveFolderSWRKey({ folderId }), updatedFolder, { revalidate: false });
};

export const trashFolders = async ({
    folderIds,
    parentId = null,
    sourceClassId = null,
}: {
    folderIds: {
        userId: string;
        folderId: string;
    }[];
    parentId?: string | null;
    sourceClassId?: string | null;
}) => {
    const currentUser = await getSWRValue(StableSWRKeys.USER);
    const currentUserId = currentUser.user.ID;

    await Promise.all(
        folderIds.map(
            async ({ userId, folderId }) =>
                await trashFolder({
                    folderId,
                    userId,
                    parentId,
                    sourceClassId,
                })
        )
    );

    const swrKey = sourceClassId
        ? resolveClassFoldersSWRKey({ classId: sourceClassId })
        : resolveFoldersSWRKey({ userId: currentUserId });

    // extra cache updater outside of the trashFolder function to fix individual cache update conflicts
    await safeLocalMutate(swrKey, (oldFolders: FoldersMap) => ({
        ...oldFolders,
        ...folderIds
            .map(({ folderId }) => ({
                [folderId]: { ...oldFolders[folderId], trash: true },
            }))
            // biome-ignore lint/performance/noAccumulatingSpread:
            .reduce((acc, val) => ({ ...acc, ...val }), {}),
    }));
};
export const trashFolder = async ({
    folderId,
    userId,
    parentId = null,
    sourceClassId = null,
}: {
    folderId: string;
    userId: string;
    parentId: string | null;
    sourceClassId?: string | null;
}) =>
    await updateFolderTrashState({
        folderId,
        userId,
        parentId,
        putInTrash: true,
        removeFromParentFolder: true,
        removeFromClass: true,
        sourceClassId,
    });

export const restoreFolders = async ({
    folderIds,
}: {
    folderIds: {
        userId: string;
        folderId: string;
    }[];
}) => {
    const currentUser = await getSWRValue(StableSWRKeys.USER);
    const currentUserId = currentUser.user.ID;

    await Promise.all(folderIds.map(({ userId, folderId }) => restoreFolder({ folderId, userId })));

    // extra cache updater outside of the restoreFolder function to fix individual cache update conflicts
    await safeLocalMutate(resolveFoldersSWRKey({ userId: currentUserId }), (oldFolders: FoldersMap) => ({
        ...oldFolders,
        ...folderIds
            .map(({ folderId }) => ({
                [folderId]: { ...oldFolders[folderId], trash: false },
            }))
            // biome-ignore lint/performance/noAccumulatingSpread:
            .reduce((acc, val) => ({ ...acc, ...val }), {}),
    }));
};

export const restoreFolder = async ({ folderId, userId }: { folderId: string; userId: string }) =>
    await updateFolderTrashState({ folderId, userId, putInTrash: false, removeFromParentFolder: true });

const updateFolderTrashState = async ({
    folderId,
    userId,
    putInTrash,
    parentId = null,
    removeFromParentFolder,
    removeFromClass,
    sourceClassId,
}: {
    folderId: string;
    userId: string;
    putInTrash: boolean;
    parentId?: string | null;
    removeFromParentFolder: boolean;
    removeFromClass?: boolean;
    sourceClassId?: string | null;
}) => {
    const topLevelFolderUpdates = {
        trash: putInTrash,
        ...(removeFromClass && { classId: null }),
        ...(removeFromParentFolder && { parentId: null }),
    };

    // inner items always preserve the nesting structure, hence we don't update the `parentId`
    const innerItemsUpdates = objectWithout(topLevelFolderUpdates, "parentId");

    await updateFolderAndNestedItemsFields({
        folderId,
        userId,
        parentId,
        sourceClassId,
        topLevelFolderUpdates,
        nestedFoldersUpdates: innerItemsUpdates,
        itemsUpdates: innerItemsUpdates,
    });
};

export const updateFolderAndNestedItemsFields = async ({
    folderId,
    userId,
    parentId,
    sourceClassId,
    topLevelFolderUpdates,
    nestedFoldersUpdates,
    itemsUpdates,
}: {
    folderId: string;
    userId: string;
    parentId?: string | null;
    sourceClassId?: string | null;
    topLevelFolderUpdates: Partial<Folder>;
    nestedFoldersUpdates: Partial<Folder>;
    itemsUpdates: Partial<Folder>;
}) => {
    const swrKey = sourceClassId
        ? resolveClassFoldersSWRKey({ classId: sourceClassId })
        : resolveFoldersSWRKey({ userId });

    await safeLocalMutate(swrKey, (oldFolders: FoldersMap) => ({
        ...oldFolders,
        [folderId]: { ...oldFolders[folderId], ...topLevelFolderUpdates },
    }));

    await safeLocalMutate(resolveFolderSWRKey({ folderId }), (oldFolder: Folder) => ({
        ...oldFolder,
        ...topLevelFolderUpdates,
    }));

    if (parentId) {
        await safeLocalMutate(resolveNestedFoldersSWRKey({ parentId }), (oldFolders: FoldersMap) => ({
            ...oldFolders,
            [folderId]: { ...oldFolders[folderId], ...topLevelFolderUpdates },
        }));

        await safeLocalMutate(resolveNestedFoldersSWRKey({ parentId }), (oldFolders: FoldersMap) => ({
            ...oldFolders,
            [folderId]: { ...oldFolders[folderId], ...nestedFoldersUpdates },
        }));
    }

    const nestedItems = await fetchFolderNestedItems({ folderId });

    await Promise.all(
        nestedItems.folderIds.map(({ userId, folderId: nestedFolderId }) =>
            updateFolderAndNestedItemsFields({
                folderId: nestedFolderId,
                userId,
                topLevelFolderUpdates: nestedFoldersUpdates,
                nestedFoldersUpdates,
                itemsUpdates,
            })
        )
    );

    await Promise.all([
        genericUpdateNotes({
            noteIds: nestedItems.noteIds,
            updatedFields: itemsUpdates as undefined as Partial<Note>,
        }),
        genericUpdateFlashcardSets({
            flashcardSetIds: nestedItems.flashcardSetIds,
            updatedFields: itemsUpdates as undefined as Partial<FlashcardSet>,
        }),
        genericUpdateMedias({
            mediaIds: nestedItems.mediaIds,
            updatedFields: itemsUpdates as undefined as Partial<Media>,
        }),
        genericUpdateAssignments({
            assignmentIds: nestedItems.assignmentIds,
            updatedFields: itemsUpdates as undefined as Partial<Assignment>,
        }),
    ]);

    await callUpdateFolder(folderId, { userId, ...topLevelFolderUpdates });
};

export const deleteFolder = async ({ folderId, userId }: { folderId: string; userId: string }) => {
    await safeLocalMutate(resolveFoldersSWRKey({ userId }), (folders: FoldersMap) => objectWithout(folders, folderId));

    const nestedItems = await fetchFolderNestedItems({ folderId });

    await Promise.all(
        nestedItems.folderIds.map(({ userId, folderId: nestedFolderId }) =>
            deleteFolder({ folderId: nestedFolderId, userId })
        )
    );

    await Promise.all([
        deleteNotes({ noteIds: nestedItems.noteIds }),
        deleteFlashcardSets({ flashcardSetIds: nestedItems.flashcardSetIds }),
        deleteMedias({ mediaIds: nestedItems.mediaIds }),
    ]);

    await callDeleteFolder(folderId, userId);
};

export const deleteFolders = async ({
    folderIds,
}: {
    folderIds: {
        userId: string;
        folderId: string;
    }[];
}) => {
    const currentUser = await getSWRValue(StableSWRKeys.USER);
    const currentUserId = currentUser.user.ID;

    await safeLocalMutate(resolveFoldersSWRKey({ userId: currentUserId }), (folders: FoldersMap) =>
        objectWithout(folders, ...folderIds.map(({ folderId }) => folderId))
    );

    await Promise.all(folderIds.map(({ userId, folderId }) => deleteFolder({ folderId, userId })));
};

export const duplicateFolder = async ({
    user,
    folderId: baseFolderId,
    overrides,
}: {
    user: UserDetails;
    folderId: string;
    overrides?: Partial<Folder>;
}) => {
    const initialFolder = await callGetFolder({ folderId: baseFolderId });
    const isOwner = initialFolder.userId === user.ID;

    const newFolder = await callCreateFolder({
        ...objectWithout(initialFolder, "userId", "folderId", "name"),
        parentId: isOwner ? initialFolder.parentId : null,
        userId: user.ID,
        folderId: uuidv4(),
        name: `${initialFolder.name} (copy)`,
        ...overrides,
    });

    await updateFolderInFolderList({
        userId: user.ID,
        folder: newFolder,
    });

    const nestedItems = await fetchFolderNestedItems({ folderId: baseFolderId });

    await Promise.all(
        nestedItems.folderIds.map(({ folderId }) =>
            duplicateFolder({
                user,
                folderId,
                overrides: {
                    parentId: newFolder.folderId,
                    public: newFolder.public,
                    password: newFolder.password,
                },
            })
        )
    );

    await Promise.all([
        duplicateNotes(nestedItems.noteIds, user, {
            folderId: newFolder.folderId,
            public: newFolder.public,
            password: newFolder.password,
        }),
        duplicateFlashcardSets(nestedItems.flashcardSetIds, user, {
            folderId: newFolder.folderId,
            public: newFolder.public,
            password: newFolder.password,
        }),
        duplicateMedias(nestedItems.mediaIds, user.ID, newFolder.folderId),
    ]);

    return newFolder;
};

const fetchFolderNestedItems = async ({ folderId }: { folderId: string }) => {
    const [folders, notes, flashcardSets, medias, assignments] = await Promise.all([
        (await mutate(
            resolveNestedFoldersSWRKey({ parentId: folderId }),
            (oldData: Record<string, Folder>) => oldData,
            {
                revalidate: false,
            }
        )) ?? callListFoldersByParent({ parentId: folderId }),
        (await mutate(resolveFolderNotesSWRKey({ folderId }), (oldData: Record<string, Note>) => oldData, {
            revalidate: false,
        })) ?? callListNotesByFolder({ folderId }),
        (await mutate(
            resolveFolderFlashcardSetsSWRKey({ folderId }),
            (oldData: Record<string, FlashcardSet>) => oldData,
            {
                revalidate: false,
            }
        )) ?? callListFlashcardSetsByFolder({ folderId }),
        (await mutate(resolveFolderMediasSWRKey({ folderId }), (oldData: Record<string, Media>) => oldData, {
            revalidate: false,
        })) ?? callListMediaByFolder({ folderId }),
        (await mutate(resolveFolderAssignmentsSWRKey({ folderId }), (oldData: Record<string, Assignment>) => oldData, {
            revalidate: false,
        })) ?? callListAssignmentsByFolder({ folderId }),
    ]);

    return {
        folderIds: Object.values(folders).map(folder => ({
            userId: folder.userId,
            folderId: folder.folderId,
        })),
        noteIds: Object.values(notes).map(note => ({
            userId: note.userId,
            noteId: note.noteId,
        })),
        flashcardSetIds: Object.values(flashcardSets).map(flashcardSet => ({
            userId: flashcardSet.userId,
            flashcardSetId: flashcardSet.flashcardSetId,
        })),
        mediaIds: Object.values(medias).map(media => ({
            userId: media.userId,
            mediaId: media.mediaId,
        })),
        assignmentIds: Object.values(assignments).map(assignment => ({
            userId: assignment.userId,
            assignmentId: assignment.assignmentId,
        })),
    };
};

export const updateFolderInFolderList = async ({ userId, folder }: { userId: string | undefined; folder: Folder }) => {
    const { folderId, classId, parentId } = folder;

    const swrKeys = [
        resolveFoldersSWRKey({ userId }),
        ...(classId ? [resolveClassFoldersSWRKey({ classId }), resolveAccessibleFoldersSWRKey({})] : []),
        ...(parentId ? [resolveNestedFoldersSWRKey({ parentId: parentId })] : []),
    ];

    return Promise.all(
        swrKeys.map(swrKey =>
            safeLocalMutate(swrKey, (oldFolders: FoldersMap) => {
                return {
                    ...oldFolders,
                    [folderId]: { ...oldFolders[folderId], ...folder },
                };
            })
        )
    );
};

export const updateNestedFolderInFolderList = async ({
    folderId,
    parentId,
    updatedFields,
}: {
    folderId: string;
    parentId: string;
    updatedFields: Partial<Folder>;
}) => {
    return await safeLocalMutate(resolveNestedFoldersSWRKey({ parentId }), (oldFolders: FoldersMap) => {
        return {
            ...oldFolders,
            [folderId]: { ...oldFolders[folderId], ...updatedFields },
        };
    });
};

export const updateFolderPublicState = async ({
    folderId,
    userId,
    parentId,
    isPublic,
    password = null,
}: {
    folderId: string;
    userId: string;
    parentId?: string;
    isPublic: boolean;
    password?: string;
}) => {
    await updateFolderInFolderList({
        userId,
        folder: { __typename: "Folder", folderId, userId, public: isPublic, password, parentId },
    });

    const { folderIds, noteIds, flashcardSetIds } = await fetchFolderNestedItems({ folderId });

    await Promise.all(
        folderIds.map(({ userId, folderId: nestedFolderId }) =>
            updateFolderPublicState({
                folderId: nestedFolderId,
                parentId: folderId,
                userId,
                isPublic,
                password,
            })
        )
    );

    await Promise.all([
        updateNotesPublicState({
            noteIds: noteIds,
            isPublic,
            sourceFolderId: folderId,
            password,
        }),
        updateFlashcardSetsPublicState({
            flashcardSetIds: flashcardSetIds,
            isPublic,
            sourceFolderId: folderId,
            password,
        }),
        // note: medias are always private
    ]);

    const updatedFolder = await callUpdateFolder(folderId, { userId, public: isPublic, password });
    await mutate(resolveFolderSWRKey({ folderId }), updatedFolder, { revalidate: false });
};

export const isFolderMoveValid = async ({
    destinationFolderId,
    selectedFolders,
    userId,
}: {
    destinationFolderId: string | null | undefined;
    selectedFolders: Record<string, Folder>;
    userId?: string | null;
}) => {
    if (!destinationFolderId) {
        return false;
    }

    if (selectedFolders[destinationFolderId]) {
        return false;
    }

    const allFolders = await callListFoldersByUser({ userId });

    const isParent = (folderId: string, compareId: string) => {
        const folder = allFolders[folderId];
        if (!folder?.parentId) {
            return false;
        }
        if (folder.parentId === compareId) {
            return true;
        }
        return isParent(folder.parentId, compareId);
    };

    for (const folder of Object.values(selectedFolders)) {
        if (isParent(destinationFolderId, folder.folderId)) {
            return false;
        }
    }

    return true;
};

export const resolveFoldersSWRKey = ({
    userId,
    classId = null,
    isEnabled = true,
}: {
    userId: string;
    classId?: string;
    isEnabled?: boolean;
}) => {
    return isEnabled && userId && ["folders", userId, ...(classId ? [classId] : [])];
};

export const resolveClassFoldersSWRKey = ({ classId, isEnabled = true }: { classId: string; isEnabled?: boolean }) => {
    return isEnabled && classId ? ["folders", classId] : null;
};

export const resolveFolderSWRKey = ({ folderId, isEnabled = true }: { folderId: string; isEnabled?: boolean }) => {
    return isEnabled && folderId ? ["folder", folderId] : null;
};

// TODO: consider removing this function, in favor of using `resolveFoldersSWRKey`
export const resolveNestedFoldersSWRKey = ({
    parentId,
    isEnabled = true,
}: {
    parentId: string | null | undefined;
    isEnabled?: boolean;
}) => {
    return isEnabled && parentId ? ["nested-folders", parentId] : null;
};

export const resolveAccessibleFoldersSWRKey = ({ isEnabled = true }) => {
    return isEnabled ? ["accessible-folders"] : null;
};

export const resolveFolderNestedItemsSWRKey = ({ folderId, isEnabled = true }) => {
    return isEnabled && folderId ? ["folder-nested-items", folderId] : null;
};
