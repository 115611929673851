import { fetchBookmarks } from "@/hooks/bookmarks/utils";
import { callGetClass } from "@/hooks/classes/graphqlUtils";
import { resolveClassSWRKey, updateClass } from "@/hooks/classes/utils";
import { StableSWRKeys, getSWRValue, safeLocalMutate } from "@/hooks/swr/swr";
import { platform } from "@/platform";
import { NoteMetadata, PartialWithRequired } from "@/types/common";
import { objectWithout, pick } from "@/utils/dataCleaning";
import { fromEntries, retry } from "@/utils/genericUtils";
import { Class, GradeNoteTestInput, Note, UserDetails } from "@knowt/syncing/graphql/schema";
import { mutate } from "swr";
import { deleteFlashcardSets, fetchFlashcardSetsMetaDataByNote } from "../flashcards/utils";
import {
    callCreateNote,
    callDeleteNote,
    callGradeNoteTest,
    callUpdateNote,
    fetchNote,
    getDefaultNoteFields,
} from "./graphqlUtils";

import { NoteTestAnswerOption } from "@/hooks/notes/types";
import { shuffleArray } from "@/utils/arrayUtils";
import { ServerClientWithCookies } from "@/utils/client/graphql";
import { now } from "@/utils/dateTimeUtils";

type NotesMetadataMap = Record<string, NoteMetadata>;

export const resolveNotesSWRKey = ({ userId, isEnabled = true }: { userId: string; isEnabled?: boolean }) => {
    return isEnabled && userId ? ["notes", userId] : null;
};

export const resolveFolderNotesSWRKey = ({ folderId, isEnabled = true }: { folderId: string; isEnabled?: boolean }) => {
    return isEnabled && folderId ? ["folder-notes", folderId] : null;
};

export const resolveClassNotesSWRKey = ({ classId, isEnabled = true }: { classId: string; isEnabled?: boolean }) => {
    return isEnabled && classId ? ["class-notes", classId] : null;
};

export const resolveNoteSWRKey = ({
    userId,
    noteId,
    isEnabled = true,
}: {
    userId: string;
    noteId?: string;
    isEnabled?: boolean;
}) => {
    return isEnabled && noteId ? ["note", noteId, userId] : null;
};

export const createNewNote = async (
    initialFields: Partial<Note>,
    user: UserDetails,
    serverClient?: ServerClientWithCookies
) => {
    const input = { ...getDefaultNoteFields(user), ...initialFields };
    const note = await callCreateNote(input, serverClient);
    if (serverClient) {
        // we are on the server, so SWR is not available
        return note;
    }
    if (note.classId) {
        // TODO: why is this needed?
        mutate(resolveClassSWRKey({ classId: note.classId }));
    }
    await updateNoteInNoteList(note);
    return mutate(resolveNoteSWRKey({ noteId: note.noteId, userId: user.ID }), note, { revalidate: false });
};

const backendDelete = async (noteId: string, userId: string) => {
    return await retry(async () => {
        await deleteNoteAssociatedItems(noteId);
        return callDeleteNote(noteId, userId);
    });
};

const deleteNoteAssociatedItems = async (noteId: string) => {
    const associatedFlashcardSets = (await fetchFlashcardSetsMetaDataByNote(noteId)).map(f => ({
        userId: f.userId,
        flashcardSetId: f.flashcardSetId,
    }));

    await deleteFlashcardSets({ flashcardSetIds: associatedFlashcardSets });
};

/**
 * Updates the truncated representation of the note in the note list
 */
export const updateNoteInNoteList = async (newNoteObj: Note) => {
    const { userId, folderId, classId } = newNoteObj;

    const relatedSWRKeys = [
        resolveNotesSWRKey({ userId }),
        ...(classId ? [resolveClassNotesSWRKey({ classId })] : []),
        ...(folderId ? [resolveFolderNotesSWRKey({ folderId })] : []),
    ];

    await Promise.all(
        relatedSWRKeys.map(swrKey =>
            safeLocalMutate(swrKey, (oldNotes: NotesMetadataMap) => ({
                ...oldNotes,
                [newNoteObj.noteId]: objectWithout(newNoteObj, "content"),
            }))
        )
    );
};

/**
 * Saves a note both locally and on the backend
 */
export const saveNote = async (updates: PartialWithRequired<Note, "userId" | "noteId">): Promise<Note> => {
    updates.updated = String(now());
    const { userId, noteId } = updates;

    const updatedNote = await callUpdateNote(updates);

    await mutate(resolveNoteSWRKey({ noteId, userId }), updatedNote, { revalidate: false });

    await updateNoteInNoteList(updatedNote);

    if (updatedNote.flashcardSetId && Object.keys(updates).some(key => SHARED_NOTE_FLASHCARD_SET_KEYS.includes(key))) {
        // backend will update the SHARED_NOTE_FLASHCARD_SET_KEYS keys of the flashcardSet
        // to follow those of the note, so we need to revalidate the cached flashcardSet:
        await mutate(["flashcard-set", updatedNote.flashcardSetId, userId]);
    }

    return updatedNote;
};

export const SHARED_NOTE_FLASHCARD_SET_KEYS = [
    "password",
    "public",
    "title",
    "tags",
    "trash",
    "subject",
    "topic",
    "exam_v2",
    "examUnit",
    "examSection",
    "folderId",
    "classId",
    "addedAt",
];

/**
 * Move a set of notes from home screen to a folder, or vice versa
 * @param noteIds
 * @param userId
 * @param destinationFolderId - null for home screen
 * @param sourceFolderId - null for home screen
 * @param optimisticUpdate - while moving file from navigation panel, the optimistic update is done manually because we're manually sorting the note inside the list
 */
export const moveNotes = async ({
    noteIds,
    sourceFolderId,
    destinationFolderId,
    sourceClassId,
    destinationClassId,
    isPublic,
    sharedSections,
}: {
    noteIds: { userId: string; noteId: string }[];
    sourceFolderId?: string | null;
    destinationFolderId?: string | null;
    sourceClassId?: string | null;
    destinationClassId?: string | null;
    isPublic?: boolean;
    sharedSections?: string[] | null;
}) => {
    await genericUpdateNotes({
        noteIds,
        sourceFolderId,
        destinationFolderId,
        sourceClassId,
        destinationClassId,
        updatedFields: {
            folderId: destinationFolderId,
            classId: destinationClassId,
            sections: sharedSections,
            ...(isPublic === undefined ? {} : { public: isPublic }),
            ...(sourceClassId !== destinationClassId ? { addedAt: now().toString() } : {}),
            ...(destinationClassId ? { password: null } : {}),
        },
    });
};

export const trashNotes = async ({
    noteIds,
    sourceFolderId,
    sourceClassId,
}: {
    noteIds: {
        userId: string;
        noteId: string;
    }[];
    sourceFolderId: string | null;
    sourceClassId?: string | null;
    destinationClassId?: string | null;
}) => {
    await updateNotesTrashState({
        noteIds,
        sourceFolderId,
        sourceClassId,
        inTrash: true,
        removeFromFolder: true,
        removeFromClass: true,
    });
};

export const restoreNotes = async ({
    noteIds,
    sourceFolderId,
}: {
    noteIds: {
        userId: string;
        noteId: string;
    }[];
    sourceFolderId: string | null;
}) => {
    await updateNotesTrashState({
        noteIds,
        sourceFolderId,
        inTrash: false,
        removeFromFolder: true,
    });
};

const updateNotesTrashState = async ({
    noteIds,
    sourceFolderId,
    sourceClassId,
    inTrash,
    removeFromFolder,
    removeFromClass,
}: {
    noteIds: {
        userId: string;
        noteId: string;
    }[];
    sourceFolderId: string | null;
    sourceClassId?: string | null;
    inTrash: boolean;
    removeFromFolder: boolean;
    removeFromClass?: boolean;
}) => {
    await genericUpdateNotes({
        noteIds,
        sourceFolderId,
        // TODO: why destinationFolderId is set to sourceFolderId ?
        destinationFolderId: removeFromFolder ? null : sourceFolderId,
        sourceClassId,
        updatedFields: {
            trash: inTrash,
            ...(removeFromClass && { classId: null }),
            ...(removeFromFolder && { folderId: null }),
        },
    });
};

export const genericUpdateNotes = async ({
    noteIds,
    sourceFolderId,
    destinationFolderId,
    sourceClassId,
    destinationClassId,
    updatedFields,
}: {
    noteIds: { userId: string; noteId: string }[];
    sourceFolderId?: string | null;
    destinationFolderId?: string | null;
    sourceClassId?: string | null;
    destinationClassId?: string | null;
    updatedFields: Partial<Note>;
}) => {
    const currentUser = await getSWRValue(StableSWRKeys.USER);
    const currentUserId = currentUser.user.ID;

    Promise.all([
        mutate(
            resolveFolderNotesSWRKey({ folderId: sourceFolderId }),
            (oldNotes: NotesMetadataMap) =>
                fromEntries(
                    Object.values(
                        updateCache(
                            oldNotes,
                            noteIds.map(({ noteId }) => noteId),
                            updatedFields
                        )
                    )
                        .filter(note => note?.folderId === sourceFolderId)
                        .map(note => [note.noteId, note])
                ),
            { revalidate: false }
        ),
        mutate(
            resolveClassNotesSWRKey({ classId: sourceClassId }),
            (oldNotes: NotesMetadataMap) =>
                updateCache(
                    oldNotes,
                    noteIds.map(({ noteId }) => noteId),
                    updatedFields
                ),
            { revalidate: false }
        ),
    ]);

    try {
        const updatedNotes = await Promise.all(
            noteIds.map(({ userId, noteId }) => saveNote({ noteId, userId, ...updatedFields }))
        );

        if (sourceFolderId !== destinationFolderId) {
            await mutate(
                resolveFolderNotesSWRKey({ folderId: destinationFolderId }),
                (oldNotes: NotesMetadataMap) => addToCache(oldNotes, updatedNotes),
                { revalidate: false }
            );
        }

        if (sourceClassId !== destinationClassId) {
            await mutate(
                resolveClassNotesSWRKey({ classId: destinationClassId }),
                (oldNotes: NotesMetadataMap) => addToCache(oldNotes, updatedNotes),
                { revalidate: false }
            );

            if (sourceClassId) {
                const sourceClass =
                    (await mutate(resolveClassSWRKey({ classId: sourceClassId }), (oldData: Class) => oldData, {
                        revalidate: false,
                    })) ?? (await callGetClass({ classId: sourceClassId }));

                const updatedPinned = sourceClass.pinned.filter(
                    itemId => !noteIds.find(({ noteId }) => itemId === noteId)
                );

                if (updatedPinned.length !== sourceClass.pinned.length) {
                    await updateClass({ classId: sourceClassId, pinned: updatedPinned }, { ID: currentUserId });
                }
            }

            if (destinationClassId) {
                const bookmarks = await fetchBookmarks({ userId: currentUserId });

                const bookmarkedNotes = noteIds
                    .filter(({ noteId }) => bookmarks.some(b => b.ID === noteId))
                    .map(({ noteId }) => noteId);

                if (bookmarkedNotes.length) {
                    const destinationClass =
                        (await mutate(
                            resolveClassSWRKey({ classId: destinationClassId }),
                            (oldData: Class) => oldData,
                            {
                                revalidate: false,
                            }
                        )) ?? (await callGetClass({ classId: destinationClassId }));

                    const updatedPinned = [...destinationClass.pinned, ...bookmarkedNotes];
                    await updateClass({ classId: destinationClassId, pinned: updatedPinned }, { ID: currentUserId });
                }
            }
        }
    } catch {
        const toast = await platform.toast();
        toast.error("Failed to move notes. Please refresh the page.");
    }
};

export const deleteNotes = async ({
    noteIds,
}: {
    noteIds: {
        userId: string;
        noteId: string;
    }[];
}) => {
    const currentUser = await getSWRValue(StableSWRKeys.USER);
    const currentUserId = currentUser.user.ID;

    await mutate(
        resolveNotesSWRKey({ userId: currentUserId }),
        (notes: NotesMetadataMap) => objectWithout(notes, ...noteIds.map(({ noteId }) => noteId)),
        {
            revalidate: false,
        }
    );
    await Promise.all(
        noteIds.map(({ userId, noteId }) => mutate(resolveNoteSWRKey({ noteId, userId }), null, { revalidate: false }))
    );

    try {
        await Promise.all(noteIds.map(({ userId, noteId }) => backendDelete(noteId, userId)));
    } catch {
        const toast = await platform.toast();
        toast.error("Failed to delete notes. Please refresh the page.");
    }
};

export const updateNotesPublicState = async ({
    noteIds,
    sourceFolderId = null,
    isPublic,
    password = null,
}: {
    noteIds: {
        userId: string;
        noteId: string;
    }[];
    isPublic: boolean;
    sourceFolderId: string | null;
    password?: string;
}) => {
    await mutate(
        resolveFolderNotesSWRKey({ folderId: sourceFolderId }),
        (oldNotes: NotesMetadataMap) =>
            updateCache(
                oldNotes,
                noteIds.map(({ noteId }) => noteId),
                { public: isPublic, password }
            ),
        { revalidate: false }
    );

    await Promise.all(noteIds.map(({ userId, noteId }) => saveNote({ noteId, userId, public: isPublic, password })));
};

const updateCache = (cache: NotesMetadataMap, noteIds: string[], updates: Partial<NoteMetadata>) => {
    const newCache = { ...cache };
    for (const noteId of noteIds) {
        if (!newCache[noteId]) {
            return;
        }

        newCache[noteId] = { ...newCache[noteId], ...updates };
    }

    return newCache;
};

const addToCache = (cache: NotesMetadataMap, notes: NoteMetadata[]) => {
    const newCache = { ...cache };
    for (const note of notes) {
        newCache[note.noteId] = note;
    }
    return newCache;
};

export const duplicateNotes = (
    noteIds: {
        userId: string;
        noteId: string;
    }[],
    user: UserDetails,
    overrides?: Partial<Note>
) => {
    return Promise.all(noteIds.map(({ noteId }) => duplicateNote(noteId, user, overrides)));
};

export const duplicateNote = async (baseNoteId: string, user: UserDetails, overrides: Partial<Note> = {}) => {
    const baseNote = await fetchNote({ noteId: baseNoteId });
    const isNoteOwner = baseNote.userId === user.ID;

    const input = {
        ...getDefaultNoteFields(user),
        ...pick(baseNote, "content", "file", "importType", "tags", "subject", "topic", "sections", "addedAt"),

        title: baseNote.title + " (copy)",
        userId: user.ID,
        schoolId: user.schoolId,
        grade: user.grade,
        ...(isNoteOwner && { folderId: baseNote.folderId, classId: baseNote.classId }),
        ...overrides,
        // always make duplicated note private
        public: false,
    };

    const newNote = await callCreateNote(input);
    await updateNoteInNoteList(newNote);

    if (newNote.classId) {
        // TODO: why is this needed?
        mutate(resolveClassSWRKey({ classId: newNote.classId }));
    }

    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Note - Created", {
        noteId: newNote.noteId,
        classId: newNote.classId,
        duplicated: true,
        importType: newNote.importType,
        xp: user?.xp,
        level: user?.level,
        streak: user?.streak,
        coins: user?.coins,
        gamificationRecords: user?.records,
    });

    return newNote;
};

export const mcOptionsToNum = (option: NoteTestAnswerOption): number => {
    switch (option) {
        case NoteTestAnswerOption.A:
            return 0;
        case NoteTestAnswerOption.B:
            return 1;
        case NoteTestAnswerOption.C:
            return 2;
        case NoteTestAnswerOption.D:
            return 3;
        case NoteTestAnswerOption.E:
            return 4;
    }
};

export const numToMCOptions = (num: number): NoteTestAnswerOption => {
    switch (num) {
        case 0:
            return NoteTestAnswerOption.A;
        case 1:
            return NoteTestAnswerOption.B;
        case 2:
            return NoteTestAnswerOption.C;
        case 3:
            return NoteTestAnswerOption.D;
        case 4:
            return NoteTestAnswerOption.E;
    }
};

export const getShuffledChoices = ({
    choices,
    answerIndex,
}: {
    choices: string[];
    answerIndex: number;
}): { choices: string[]; answerKey: NoteTestAnswerOption } => {
    const pos = [0, 1, 2, 3];
    const shuffledPos = shuffleArray(pos);

    const answerIdx = shuffledPos.indexOf(answerIndex);

    const mappedChoices: string[] = [];
    for (let i = 0; i < pos.length; i++) {
        mappedChoices.push(choices[shuffledPos[i]]);
    }

    return { choices: mappedChoices, answerKey: numToMCOptions(answerIdx) };
};

export const gradeNoteTest = async ({
    noteId,
    userId,
    ...input
}: Omit<GradeNoteTestInput, "score"> & {
    userId?: string | null;
    correct: number;
    incorrect: number;
    skipped: number;
}) => {
    const studySession = await callGradeNoteTest({ userId, noteId, ...input });

    mutate(["study-session", userId, noteId], studySession, { revalidate: false });
    return studySession;
};
