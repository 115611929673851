import { QueryString } from "@knowt/syncing/types/common";
import { Note } from "@knowt/syncing/graphql/schema";
import { TOOL_URL } from "@knowt/syncing/utils/aiTools";
import { TITLE_FILLER, UNTITLED } from "@knowt/syncing/utils/dataCleaning";
import { createQueryStrings } from "@knowt/syncing/utils/genericUtils";
import { cleanTitle } from "@knowt/syncing/utils/stringUtils";

export const getQueryStringByName = (url: string, name: string) => {
    const match = RegExp("[?&]" + name + "=([^&]*)").exec(url);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
};

export const getPathFromUrl = (url: string) => {
    return url?.split("?")?.[0];
};

export const slugifyText = (input: string) => {
    if (!input) return "";

    // make lower case and trim
    let slug = input.toLowerCase().trim();

    // remove accents from charaters
    // biome-ignore lint: suspicious/noMisleadingCharacterClass
    slug = slug.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    // replace invalid chars with spaces
    slug = slug.replace(/[^a-z0-9\s-]/g, " ").trim();

    // replace multiple spaces or hyphens with a single hyphen
    slug = slug.replace(/[\s-]+/g, "-");

    return slug;
};

export const getDraftNoteUrl = (note: Note) => {
    if (!note) {
        return "";
    }

    if (note.mediaId && note.isAI) {
        return `/ai-media-note/${note.mediaId}`;
    }

    if (note.aiTool) {
        return `free-ai-tools-for-teachers/${TOOL_URL[note.aiTool]}/${note.noteId}`;
    }

    return getNoteUrl({ noteId: note.noteId, title: note.title });
};

export const getNoteUrl = ({
    noteId,
    title,
    queryStrings = {},
    extraPaths = [],
}: {
    noteId: string;
    title?: string | null;
    queryStrings?: Record<string, QueryString>;
    extraPaths?: string[];
}) => {
    const baseUrl = `/note/${noteId}`;

    const cleanedTitle = "/" + (title ? cleanTitle(title) : UNTITLED);

    return addExtraUrlData({
        url: baseUrl + (cleanedTitle === "/" ? `/${TITLE_FILLER}` : cleanedTitle),
        queryStrings,
        extraPaths,
    });
};

export const addExtraUrlData = ({
    url,
    queryStrings = {},
    extraPaths: _extraPaths = [],
}: {
    url: string;
    queryStrings?: Record<string, QueryString>;
    extraPaths?: string[];
}) => {
    const params = createQueryStrings(queryStrings, encodeURIComponent);
    const extraPaths = _extraPaths.filter(Boolean);
    return `${url}${extraPaths.length ? `/${extraPaths.join("/")}` : ""}${params}`;
};
