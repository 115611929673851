import { __DEV__ } from "@/lib/constants";
import packageInfo from "@/package.json";

export const clientId = "82462445097-2os2arme5b4m6v9q7qsb7ch2jgfsffk8.apps.googleusercontent.com";
export const GOOGLE_SDK_URL = "https://apis.google.com/js/api.js";
export const DRIVE_SCOPE = ["https://www.googleapis.com/auth/drive.readonly"];
export const CLASSROOM_SCOPE = [
    "https://www.googleapis.com/auth/classroom.courses.readonly",
    "https://www.googleapis.com/auth/classroom.rosters.readonly",
    "https://www.googleapis.com/auth/classroom.profile.emails",
];

export const GA4_MEASUREMENT_ID = "G-YJF6W9W6FD";
export const GOOGLE_ADS_MEASUREMENT_ID = "AW-483417008";
export const GA4_ADS_MEASUREMENT_ID = "G-6FQRHSG3RT";
export const GOOGLE_API_KEY = "AIzaSyA9SosamJYTeZrzE_9rIgQaCGNKqvUQyd0";

export const BASE_URL = "https://knowt.com";
export const ASSETS_URL = "https://s3.amazonaws.com/knowt.com";
export const DEVELOPMENT_BUILD = !__DEV__ && packageInfo.development;
export const isDevelopment = packageInfo.development;

export const redirectSignIn = `${BASE_URL}/home`;
export const redirectSignOut = `${BASE_URL}/logout`;

export const oauth = {
    domain: "authprod.knowt.com",
    scopes: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn,
    redirectSignOut,
    responseType: "code",
};

export const STRIPE_PUBLISHABLE_KEY =
    "pk_live_51MHIDPKXJM8qxW7RAW2Nc3kAVpwNbjghcMi4MpV7iOg8mppmc1PLKN7UlqZBiRUUMECsFfYd8y2501ntVOUDXr8b00lPhkBLpP";

export const CHROME_EXTENSION_URL =
    "https://chrome.google.com/webstore/detail/knowt-quizlet-import-ai-n/akegecpdcdbkjioddaingaedacjgfjhm";

export const APP_STORE_URL = "https://apps.apple.com/us/app/knowt-ai-flashcards-notes/id6463744184";
export const PLAY_STORE_URL = "https://play.google.com/store/apps/details?id=com.knowt.app";

export const EDLINK_CLIENT_ID = "2ade6324-5a94-4b72-9d2a-90464c934ea4";
export const SSO_LOGIN_URL = `https://ed.link/sso/login?client_id=${EDLINK_CLIENT_ID}&redirect_uri=${redirectSignIn}sso/login`;

export const PAYPAL_CLIENT_ID = "Ad76i2VWnNuysS13xTugwSej0n9V8JBl1jcFO-_YwdUhOKEXFNmzlVoqJsRTkv8Q4tlosrv9TpMVhNI_";
export const PAYPAL_REST_API = "https://api-m.paypal.com/v1";
