import {
    createAssignment,
    createAssignmentFields,
    createAssignmentSession,
    deleteAssignment,
    updateAssignment,
    updateAssignmentFields,
    updateAssignmentSession,
} from "@/graphql/mutations";
import {
    getAssignment,
    getAssignmentSession,
    listAssignments,
    listAssignmentsByClass,
    listAssignmentsByFolder,
    listAssignmentSessions,
    listAssignmentSessionsByUser,
} from "@/graphql/queries";
import {
    Assignment,
    AssignmentSession,
    CreateAssignmentInput,
    UpdateAssignmentInput,
    CreateAssignmentSessionInput,
    UpdateAssignmentSessionInput,
    CreateAssignmentFieldsInput,
    UpdateAssignmentFieldsInput,
    AssignmentSessionAndMessages,
    AssignmentStatus,
} from "@/graphql/schema";
import { client, listGroupedData } from "@/utils/client/graphql";
import { v4 as uuidv4 } from "uuid";

import { ServerClientWithCookies } from "@/utils/client/graphql";
import { now } from "@/utils/dateTimeUtils";
import { omit } from "lodash";

export const callListAssignments = async ({
    userId,
    serverClient,
}: {
    userId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        listQuery: listAssignments,
        groupingKey: "assignmentId",
        input: { userId },
        queryName: "listAssignments",
        ignoreTrashed: false,
        serverClient,
    })) as Promise<Record<string, Assignment>>;
};

export const callListAssignmentsByClass = async ({
    classId,
    serverClient,
}: {
    classId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        listQuery: listAssignmentsByClass,
        groupingKey: "assignmentId",
        input: { classId },
        queryName: "listAssignmentsByClass",
        ignoreTrashed: false,
        serverClient,
    })) as Promise<Record<string, Assignment>>;
};

export const callListAssignmentsByFolder = async ({
    folderId,
    serverClient,
}: {
    folderId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        listQuery: listAssignmentsByFolder,
        groupingKey: "assignmentId",
        input: { folderId },
        queryName: "listAssignmentsByFolder",
        ignoreTrashed: false,
        serverClient,
    })) as Promise<Record<string, Assignment>>;
};

export const callListAssignmentSessions = async ({
    assignmentId,
    serverClient,
}: {
    assignmentId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        listQuery: listAssignmentSessions,
        groupingKey: "sessionId",
        input: { assignmentId },
        queryName: "listAssignmentSessions",
        ignoreTrashed: false,
        serverClient,
    })) as Promise<Record<string, AssignmentSession>>;
};

export const callListAssignmentSessionsByUser = async ({
    userId,
    serverClient,
}: {
    userId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        listQuery: listAssignmentSessionsByUser,
        groupingKey: "assignmentId",
        input: { userId },
        queryName: "listAssignmentSessionsByUser",
        ignoreTrashed: false,
        serverClient,
    })) as Promise<Record<string, AssignmentSession>>;
};

export const callCreateAssignment = async ({
    input,
    serverClient,
}: {
    input: CreateAssignmentInput;
    serverClient?: ServerClientWithCookies;
}) => {
    const result = await client
        .mutate({
            mutation: createAssignment,
            variables: { input: input },
            serverClient,
        })
        .then(({ data }) => data.createAssignment);

    return result.item;
};

export const callUpdateAssignment = async ({
    input,
    serverClient,
}: {
    input: UpdateAssignmentInput;
    serverClient?: ServerClientWithCookies;
}) => {
    const result = await client
        .mutate({
            mutation: updateAssignment,
            variables: { input: input },
            serverClient,
        })
        .then(({ data }) => data.updateAssignment);

    return result.item;
};

export const callGetAssignment = async ({
    assignmentId,
    serverClient,
}: { assignmentId: string; serverClient?: ServerClientWithCookies }) => {
    const result = await client
        .query({
            query: getAssignment,
            variables: { input: { assignmentId } },
            serverClient,
        })
        .then(({ data }) => data.getAssignment);

    return result.item;
};

export const callGetAssignmentSession = async ({
    assignmentId,
    sessionId,
    serverClient,
}: {
    assignmentId: string;
    sessionId: string;
    serverClient?: ServerClientWithCookies;
}): Promise<AssignmentSessionAndMessages> => {
    if (sessionId === "PREVIEW") {
        // if we are in preview mode, return a mock session and messages
        return {
            session: {
                assignmentId,
                sessionId: "PREVIEW",
                created: now().toString(),
                updated: now().toString(),
                userId: "",
                status: AssignmentStatus.IN_PROGRESS,
            },
            messages: [],
        };
    }
    return await client
        .query({
            query: getAssignmentSession,
            variables: { input: { assignmentId, sessionId } },
            serverClient,
        })
        .then(({ data }) => data.getAssignmentSession);
};

export const callCreateAssignmentSession = async ({
    input,
    serverClient,
}: { input: Omit<CreateAssignmentSessionInput, "sessionId" | "created">; serverClient?: ServerClientWithCookies }) => {
    return await client
        .mutate({
            mutation: createAssignmentSession,
            variables: {
                input: {
                    sessionId: uuidv4(),
                    created: now().toString(),
                    ...input,
                },
            },
            serverClient,
        })
        .then(({ data }) => data.createAssignmentSession);
};

export const callUpdateAssignmentSession = async ({
    input,
    serverClient,
}: { input: Omit<UpdateAssignmentSessionInput, "userId">; serverClient?: ServerClientWithCookies }) => {
    return await client
        .mutate({
            mutation: updateAssignmentSession,
            variables: {
                // there's no chance frontend will have to pass userId for this api,
                // it might cause some side effects in the future, so, not letting this api
                // edit userId for safety
                input: omit(input, "userId"),
            },
            serverClient,
        })
        .then(({ data }) => data.updateAssignmentSession);
};

export const callCreateAssignmentFields = async ({
    input,
    serverClient,
}: { input: CreateAssignmentFieldsInput; serverClient?: ServerClientWithCookies }) => {
    return await client
        .mutate({
            mutation: createAssignmentFields,
            variables: { input },
            serverClient,
        })
        .then(({ data }) => data.createAssignmentFields);
};

export const callUpdateAssignmentFields = async ({
    input,
    serverClient,
}: { input: UpdateAssignmentFieldsInput; serverClient?: ServerClientWithCookies }) => {
    return await client
        .mutate({
            mutation: updateAssignmentFields,
            variables: { input },
            serverClient,
        })
        .then(({ data }) => data.updateAssignmentFields);
};

export const callDeleteAssignment = async ({
    assignmentId,
    userId,
    serverClient,
}: { assignmentId: string; userId: string; serverClient?: ServerClientWithCookies }) => {
    return await client
        .mutate({
            mutation: deleteAssignment,
            variables: { input: { assignmentId, userId } },
            serverClient,
        })
        .then(({ data }) => data.deleteAssignment);
};
