import { callListFoldersByUser } from "@/hooks/folders/graphqlUtils";
import { resolveFoldersSWRKey } from "@/hooks/folders/utils";
import { LocalUser } from "@/hooks/user/types";
import { platform } from "@/platform";
import {
    Class,
    ClassEnrollment,
    ClassMemberInput,
    ClassMemberWithDetails,
    ClassRole,
    ClassSection,
    CreateClassInput,
    DeleteClassInput,
    UpdateClassInput,
    UserDetails,
} from "@knowt/syncing/graphql/schema";
import { omit } from "lodash-es";
import { mutate } from "swr";
import { callCreateClass, callDeleteClass, callJoinClass, callLeaveClass, callUpdateClass } from "./graphqlUtils";

import { now } from "@/utils/dateTimeUtils";
import { union } from "lodash-es";
import { StableSWRKeys, safeLocalMutate } from "../swr/swr";

export const resolveClassSWRKey = ({ classId, isEnabled = true }: { classId: string; isEnabled?: boolean }) => {
    return isEnabled && classId ? ["class", classId] : null;
};

export const createClass = async (
    initialFields: Omit<CreateClassInput, "created" | "updated" | "pinned" | "userId">,
    user: UserDetails | undefined
) => {
    if (!user?.ID) throw new Error("Not Logged in");

    const defaultFields = {
        userId: user.ID,
        updated: now().toString(),
        created: now().toString(),
        pinned: [],
    };

    const input = { ...defaultFields, ...initialFields };
    const course = await callCreateClass(input);

    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Class - Created", {
        classId: course.classId,
        userId: user.ID,
        numSections: course.sections.length,
        grade: course.grade,
        coins: user?.coins,
        streak: user?.streak,
        xp: user?.xp,
    });

    await mutate(
        resolveClassesSWRKey({ userId: user.ID }),
        (data: Record<string, Class> | undefined) => {
            if (data) {
                return {
                    ...data,
                    [course.classId]: course,
                };
            }
            return data;
        },
        {
            revalidate: false,
        }
    );

    safeLocalMutate(StableSWRKeys.USER, (data: LocalUser) => {
        return {
            ...data,
            user: {
                ...data.user,
                classes: {
                    ...data.user.classes,
                    teacher: data.user.classes?.teacher
                        ? [...data.user.classes.teacher, course.classId]
                        : [course.classId],
                },
            },
        };
    });

    return course;
};

export const updateClass = async (
    _updates: Omit<UpdateClassInput, "updated">,
    user: Pick<UserDetails, "ID"> | undefined
) => {
    let updates: UpdateClassInput = { ..._updates, updated: now().toString() };

    if (updates.members) {
        updates = {
            ...updates,
            members: updates.members
                // owner is a fake role, not a real member of this array
                .filter(({ role }) => role !== ClassRole.OWNER)
                .map(
                    member =>
                        ({
                            userId: member.userId,
                            role: member.role,
                            sections: member.sections,
                            pending: member.pending,
                        }) as ClassMemberInput
                ),
        };
    }

    const input = { ...updates, updated: now().toString() };

    const course = await callUpdateClass(input);

    await mutate(
        resolveClassSWRKey({ classId: course.classId }),
        data => {
            const ownerMember = data.members?.find(member => member.role === ClassRole.OWNER);

            let members = data?.members || [];
            if (updates.members) {
                const newMembers = (updates.members || []).reduce(
                    (acc, member) => ({
                        // biome-ignore lint: noAccumulatingSpread
                        ...acc,
                        [member.userId]: member,
                    }),
                    {} as Record<string, ClassMemberWithDetails>
                );

                members = updates.members.map(oldMember => ({
                    ...members.find(member => member.userId === oldMember.userId),
                    sections: newMembers[oldMember.userId]?.sections || oldMember.sections,
                    role: newMembers[oldMember.userId]?.role || oldMember.role,
                }));
            }

            return {
                ...course,
                // reuse old members
                members: union(members, [ownerMember]),
            };
        },
        { revalidate: false }
    );

    const classesListSWRKey = resolveClassesSWRKey({ userId: user.ID });

    await mutate(
        classesListSWRKey,
        (data: Record<string, Class> | undefined) => {
            if (data) {
                return { ...data, [course.classId]: course };
            }
            return data;
        },
        { revalidate: false }
    );
};

export const joinClass = async ({
    classId,
    sections,
    userId,
}: {
    classId: string;
    sections: string[];
    userId: string | undefined;
}) => {
    if (!userId) throw new Error("Not Logged in");

    const course = await callJoinClass({ classId, sections });

    await mutate(
        resolveClassesSWRKey({ userId }),
        (data: Record<string, Class> | undefined) => {
            if (data) {
                return {
                    ...data,
                    [course.classId]: course,
                };
            }
            return data;
        },
        {
            revalidate: false,
        }
    );

    const classEnrollment: ClassEnrollment = {
        __typename: "ClassEnrollment",
        classId,
        role: ClassRole.STUDENT,
        teacherId: course.userId,
        sections: sections,
    };

    const userResponse = (await safeLocalMutate(StableSWRKeys.USER, (data: LocalUser) => {
        return {
            ...data,
            user: {
                ...data.user,
                classes: {
                    ...data.user.classes,
                    enrolled: data?.user?.classes?.enrolled
                        ? [...data.user.classes.enrolled, classEnrollment]
                        : [classEnrollment],
                },
            },
        };
    })) as unknown as LocalUser;

    const mixpanel = await platform.analytics.mixpanel();

    mixpanel.track("Join Class - Completed", {
        classId: course.classId,
        userId: userId,
        numSections: course.sections.length,
        streak: userResponse?.user?.streak,
        xp: userResponse?.user?.xp,
        coins: userResponse?.user?.coins,
        gamificationRecords: userResponse?.user?.records,
    });

    return course;
};

export const leaveClass = async ({ classId, userId }: { classId: string; userId: string | null | undefined }) => {
    if (!userId) throw new Error("Not Logged in");

    const course = await callLeaveClass({ classId });

    mutate(resolveClassesSWRKey({ userId }), (data: string[] | undefined) => {
        return omit(data, course.classId);
    });

    safeLocalMutate(StableSWRKeys.USER, (data: LocalUser) => {
        return {
            ...data,
            user: {
                ...data.user,
                classes: {
                    ...data.user.classes,
                    enrolled: data.user.classes?.enrolled?.filter(({ classId }) => classId !== course.classId) || [],
                },
            },
        };
    });

    return course;
};

export const deleteClass = async ({ classId }: DeleteClassInput) => {
    const course = await callDeleteClass({
        classId,
    });

    const mixpanel = await platform.analytics.mixpanel();

    await mixpanel.track("Class - Deleted", {
        classId: course?.classId,
        userId: course?.userId,
        school: course?.schoolId,
        numberOfSections: course?.sections?.length,
    });

    await mutate(
        resolveClassesSWRKey({ userId: course.userId }),
        (data: Record<string, Class> | undefined) => {
            return omit(data, course.classId);
        },
        {
            revalidate: false,
        }
    );

    await mutate(resolveClassSWRKey({ classId: course.classId }), null, { revalidate: false });

    // on backend, a backup folder is created and all items are moved to it .
    // Note that mutate(key) alone is not enough since the folders hook might not be
    // mounted at that time, so we need to call the fetcher `callListFoldersByUser` ourselves.
    await mutate(resolveFoldersSWRKey({ userId: course.userId }), callListFoldersByUser({ userId: course.userId }), {
        revalidate: false,
    });

    return course;
};

export const resolveClassesSWRKey = ({ userId, isEnabled = true }: { userId: string; isEnabled?: boolean }) => {
    return isEnabled ? ["classes", userId] : null;
};

export const resolveClassMembersSWRKey = ({ classId, isEnabled = true }: { classId: string; isEnabled?: boolean }) => {
    return isEnabled && classId && ["classMembers", classId];
};

export const getVisibleSections = ({
    sections,
    itemSections,
}: {
    sections: ClassSection[] | null | undefined;
    itemSections: string[] | null | undefined;
}) => {
    if (!sections) return undefined;

    return itemSections ? sections.filter(({ id }) => itemSections?.includes(id)) : undefined;
};
