import {
    getNoteMetadata,
    listNotesByClassNoContent,
    listNotesByFolderNoContent,
    listNotesNoContent,
} from "@/graphql/customQueries";
import { createNoteV2, deleteNote, gradeNoteTest, updateNoteV2 } from "@/graphql/mutations";
import { getNote, listNotes } from "@/graphql/queries";
import { callGetRawFlashcardSet } from "@/hooks/flashcards/graphqlUtils";
import { callGetFolder } from "@/hooks/folders/graphqlUtils";
import { handleMaybeProgressing } from "@/hooks/gamification/monitoring/progressing";
import { callGetMedia } from "@/hooks/media/graphqlUtils";
import { platform } from "@/platform";
import { NoteMetadata, PartialWithRequired } from "@/types/common";
import { CACHE_ENV, SHOULD_PERSIST_CACHE } from "@/utils/client/configs";
import { ServerClientWithCookies, client, listGroupedData } from "@/utils/client/graphql";
import {
    DEFAULT_NOTE_CONTENT,
    DEFAULT_NOTE_SUMMARY,
    DEFAULT_NOTE_TITLE,
    scrapeEmptyFields,
} from "@/utils/dataCleaning";
import { TIME_SECONDS, now } from "@/utils/dateTimeUtils";
import { delay, fromEntries, retry } from "@/utils/genericUtils";
import { CreateNoteInput, GradeNoteTestInput, Note, UserDetails } from "@knowt/syncing/graphql/schema";
import { memoize } from "nextjs-better-unstable-cache";
import { v4 as uuidv4 } from "uuid";

// Takes in a note object and ensures that it has all necessary data
export const fillInNoteData = (note: Note): Note | null => {
    if (!note) return null;

    note.title = note.title || DEFAULT_NOTE_TITLE;
    note.content = note.content || DEFAULT_NOTE_CONTENT;
    note.summary = note.content.slice(0, 300) || DEFAULT_NOTE_SUMMARY;
    note.trash = !!note.trash;
    note.public = !!note.public;
    note.classPublic = !!note.classPublic;
    note.updated = note.updated || String(now());
    note.created = note.created || note.updated;

    return note;
};

export const fetchNote = async ({
    noteId,
    serverClient,
    password,
}: {
    noteId?: string;
    serverClient?: ServerClientWithCookies;
    password?: string;
}) => {
    const params = { noteId, serverClient, password };
    // TODO: temporarily disabled memoization
    // return EXAM_NOTES.includes(noteId) ? await callGetNoteMemoized(params) : await callGetNote(params);
    return await callGetNote(params);
};

const callGetNote = async ({
    noteId,
    serverClient,
    password,
}: {
    noteId?: string;
    serverClient?: ServerClientWithCookies;
    password?: string;
}) => {
    try {
        const { data } = await client.query({
            query: getNote,
            variables: { input: scrapeEmptyFields({ noteId, password }) },
            serverClient,
        });

        return fillInNoteData(data.getNote);
    } catch (error) {
        const { report } = await platform.analytics.logging();
        report(error, "getNote", scrapeEmptyFields({ noteId, password }));
        throw error;
    }
};

const _callGetNoteMemoized = memoize(callGetNote, {
    persist: SHOULD_PERSIST_CACHE,
    duration: TIME_SECONDS.WEEK,
    revalidateTags: ({ noteId, password }) => [CACHE_ENV, "get-notes", noteId, `password:${password}`],
    logid: "get-note",
});

export const callListNotesNoContent = async ({
    userId,
    serverClient,
}: {
    userId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listGroupedData({
        listQuery: listNotesNoContent,
        groupingKey: "noteId",
        input: { userId },
        queryName: "listNotes",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, Note>;
};

export const fetchNoteMetadata = async ({ noteId }: { noteId: string }): Promise<NoteMetadata | null> => {
    try {
        const { data } = await client.query({
            query: getNoteMetadata,
            variables: { input: { noteId } },
        });

        return data.getNote;
    } catch (error) {
        const { report } = await platform.analytics.logging();
        report(error, "getNote", { noteId });
        return null;
    }
};

export const fetchNotesMetadata = async (noteIds: string[]): Promise<Record<string, NoteMetadata>> => {
    return fromEntries(
        (await Promise.all(noteIds.map(noteId => fetchNoteMetadata({ noteId }))))
            .filter(Boolean)
            .map(note => [note.noteId, note])
    );
};

export const callListNotesWithContent = async (userId: string | null | undefined) => {
    if (!userId) return null;

    const notesWithContent = (await listGroupedData({
        listQuery: listNotes,
        groupingKey: "noteId",
        input: { userId },
        queryName: "listNotes",
        ignoreTrashed: false,
    })) as Record<string, Note>;

    return fromEntries(Object.values(notesWithContent).map(note => [note.noteId, note.content]));
};

export const callListNotesByFolder = async ({
    folderId,
    password,
    serverClient,
}: {
    folderId: string;
    password?: string;
    serverClient?: ServerClientWithCookies;
}): Promise<Record<string, Note>> => {
    if (!folderId) return {};

    return (await listGroupedData({
        listQuery: listNotesByFolderNoContent,
        groupingKey: "noteId",
        input: { folderId, password },
        queryName: "listNotesByFolder",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, Note>;
};

export const callListNotesByClass = async ({
    classId,
    serverClient,
}: {
    classId: string;
    serverClient?: ServerClientWithCookies;
}): Promise<Record<string, Note>> => {
    if (!classId) return {};

    return (await listGroupedData({
        listQuery: listNotesByClassNoContent,
        groupingKey: "noteId",
        input: { classId },
        queryName: "listNotesByClass",
        ignoreTrashed: false,
        serverClient,
    })) as Record<string, Note>;
};

export const getDefaultNoteFields = (user: UserDetails): PartialWithRequired<Note, "noteId" | "userId"> => {
    const defaultFields: PartialWithRequired<Note, "noteId" | "userId"> = {
        noteId: uuidv4(),
        userId: user.ID,
    };

    defaultFields.title = DEFAULT_NOTE_TITLE;
    defaultFields.content = DEFAULT_NOTE_CONTENT;
    defaultFields.summary = DEFAULT_NOTE_SUMMARY;

    defaultFields.trash = false;

    defaultFields.schoolId = user.schoolId;
    defaultFields.grade = user.grade;

    defaultFields.updated = String(now());
    defaultFields.created = defaultFields.updated;

    return defaultFields;
};

export const callCreateNote = async (initialFields: CreateNoteInput, serverClient?: ServerClientWithCookies) => {
    return await retry(async () => {
        const privacySettings = await getPrivacySettings(initialFields);
        const input = scrapeEmptyFields({ ...initialFields, ...privacySettings });
        try {
            const { data } = await client.mutate({
                mutation: createNoteV2,
                variables: { input },
                serverClient,
            });

            // wait for the note to be navigated to
            delay(() => handleMaybeProgressing(data.createNoteV2), 3_000);

            return data.createNoteV2.item as Note;
        } catch (error) {
            const { report } = await platform.analytics.logging();
            report(error, "createNote", input);
            throw error;
        }
    });
};

const getPrivacySettings = async (initialFields: Partial<Note>): Promise<Pick<Note, "public" | "password">> => {
    if (initialFields.password !== undefined) return { public: false, password: initialFields.password };
    if (typeof initialFields.public === "boolean") return { public: initialFields.public, password: null };
    if (initialFields.mediaId) {
        const media = await callGetMedia({ mediaId: initialFields.mediaId });
        return { public: media.public, password: media.password };
    }
    if (initialFields.flashcardSetId) {
        const rawFlashcardSet = await callGetRawFlashcardSet({ flashcardSetId: initialFields.flashcardSetId });
        return { public: rawFlashcardSet.public, password: rawFlashcardSet.password };
    }
    if (initialFields.folderId) {
        const parentFolder = await callGetFolder({ folderId: initialFields.folderId });
        return { public: parentFolder.public, password: parentFolder.password };
    }
    return { public: false, password: null };
};

export const callUpdateNote = async (updates: PartialWithRequired<Note, "userId" | "noteId">) => {
    return await retry(async () => {
        try {
            const { data } = await client.mutate({
                mutation: updateNoteV2,
                variables: {
                    input: {
                        updated: now().toString(),
                        ...updates,
                    },
                },
            });

            await handleMaybeProgressing(data.updateNoteV2);

            return data.updateNoteV2.item as Note;
        } catch (error) {
            const { report } = await platform.analytics.logging();
            report(error, "updateNote", updates);
            throw error;
        }
    });
};

export const callDeleteNote = async (noteId: string, userId: string) => {
    try {
        return await client.mutate({
            mutation: deleteNote,
            variables: { input: { noteId, userId } },
        });
    } catch (error) {
        const { report } = await platform.analytics.logging();
        report(error, "deleteNote", { noteId });
        throw error;
    }
};

export const callGradeNoteTest = async ({
    userId,
    correct,
    incorrect,
    skipped,
    ...input
}: Omit<GradeNoteTestInput, "score"> & {
    userId?: string | null;
    correct: number;
    incorrect: number;
    skipped: number;
}) => {
    if (!userId) return null;

    return await client
        .mutate({
            mutation: gradeNoteTest,
            variables: { input: { ...input, score: `${correct}/${incorrect}/${skipped}` } },
        })
        .then(({ data }) => data.gradeNoteTest);
};
