import packageInfo from "@/package.json";

const isMixpanelInitialized = () =>
	window && (window as any).mixpanel && (window as any).mixpanel.track;

export const MIXPANEL_DEBUG_MODE = true;

export const MIXPANEL_PROJECT_ID = packageInfo.development
	? "db3f8de810a1c3e573663a71617deb88"
	: "34142dcacfcd7635e660802a2743e57c";

const Mixpanel = {
	init: (ignore_dnt = true) => {
		try {
			(window as any).mixpanel.init(
				MIXPANEL_PROJECT_ID,
				{
					debug: MIXPANEL_DEBUG_MODE,
					ignore_dnt,
				},
				`mixpanel-${MIXPANEL_PROJECT_ID}`,
			);
		} catch {
			// pass
		}
	},
	identify: (id: string) => {
		try {
			if (isMixpanelInitialized()) {
				(window as any).mixpanel.identify(id);
			}
		} catch {
			// pass
		}
	},
	alias: (id: string) => {
		try {
			if (isMixpanelInitialized()) {
				(window as any).mixpanel.alias(id);
			}
		} catch {
			// pass
		}
	},
	track: async (
		event: string,
		props: {
			[key: string]: unknown;
		} = {},
	) => {
		try {
			while (!isMixpanelInitialized()) {
				await new Promise((resolve) => setTimeout(resolve, 100));
			}
			const params = new URL(window.location.href).searchParams;
			const paramsObj = {};
			for (const [key, value] of params) {
				paramsObj[key] = value;
			}

			const additionalInfo = {
				appVersion: packageInfo.version,
				development: packageInfo.development,
				pathname: window?.location?.pathname,
				deviceInfo: window?.clientInformation?.platform,
				screenHeight: window?.screen?.height,
				screenWidth: window?.screen?.width,
				queryParams: paramsObj,
				httpreferrer: document?.referrer,
				platformOS: "web",
			};

			(window as any).mixpanel.track(event, {
				...props,
				...additionalInfo,
				appVersion: packageInfo.version,
			});
		} catch {
			// pass
		}
	},
	people: {
		set: (props: { [key: string]: unknown }) => {
			try {
				if (isMixpanelInitialized()) {
					(window as any).mixpanel.people.set(props);
				}
			} catch {
				// pass
			}
		},
	},
	reset: () => {
		try {
			if (isMixpanelInitialized()) {
				(window as any).mixpanel.reset();
			}
		} catch {
			// pass
		}
	},
};

export default Mixpanel;
