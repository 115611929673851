import { mdToHtml } from "../md/parsing/parse";
import { mdToHtml as mdToHtmlFast } from "../md/parsing/fast/parse";
import { isHtml } from "./isHtml";
import showdown from "showdown";

type ContentType = "note" | "flashcard";

const converter = new showdown.Converter({
    tables: true,
    headerLevelStart: 2,
    simpleLineBreaks: true,
    disableForced4SpacesIndentedSublists: true,
    backslashEscapesHTMLTags: true,
});

export const getHtmlFromContent = ({
    content,
}: {
    content: string;
    type?: ContentType;
}) => {
    if (!content) return "";
    const isHtmlContent = isHtml(content);

    if (isHtmlContent) return content;

    return converter.makeHtml(content);
};

export const getHtmlFromContentOLD = ({ content, type }: { content: string; type: ContentType }) => {
    if (!content) return "";
    const isHtmlContent = isHtml(content);
    if (isHtmlContent) return content;

    return mdToHtml(content, type);
};

export const getHtmlFromContentFast = ({ content, type }: { content: string; type: ContentType }) => {
    if (!content) return "";
    if (isHtml(content)) return content;
    return mdToHtmlFast(content, type);
};
