export const isHtml = (content: string) => {
    // we will give more priority to HTML
    if (containsHtml(content)) return true;

    // if it has html, then it might be a html content,
    // or it might be a markdown content that contains html.

    return false;
};

const containsHtml = (content: string) => {
    // Quick check for common HTML tags
    const htmlTagRegex = /<\/?(ol|ul|h[1-6]|p|div|span|a|strong|em|table|tr|td|th)>/i;

    if (htmlTagRegex.test(content)) {
        return true;
    }

    return false;
};

// very loose check to see if a html string has color, bold, italic, underline, etc.
export const hasFormatting = (content: string) => {
    return (
        /style="[^"]*"/g.test(content) ||
        /data-color="/g.test(content) ||
        /<em>/g.test(content) ||
        /<strong>/g.test(content) ||
        /<ul><li>/g.test(content) ||
        /<ol><li>/g.test(content)
    );
};
