"use client";

import Mixpanel from "@/utils/analytics/Mixpanel";
import { ItemType, UpgradeEvent } from "@knowt/syncing/graphql/schema";
import noop from "@knowt/syncing/utils/noop";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import { DialogProps } from "@mui/material/Dialog";
import { useCallback, useState } from "react";

type AdditionalContext = {
    itemId?: string;
    itemType?: ItemType;
    isVideo?: boolean;
};

export type OpenUpgradePopupProps = {
    event: UpgradeEvent;
    context: AdditionalContext;
    onClose?: () => void;
    isForPromotion?: boolean;
    dialogProps?: Omit<DialogProps, "open" | "onClose" | "PaperProps" | "style" | "children">;
};

type UpgradePopupContextProps = {
    isOpen: boolean;
    event: UpgradeEvent;
    context: AdditionalContext;
    onClose?: () => void;
    openUpgradePopup: (props: OpenUpgradePopupProps) => void;
    closeUpgradePopup: () => void;
    isForPromotion: boolean;
    dialogProps?: Omit<DialogProps, "open" | "onClose" | "PaperProps" | "style" | "children">;
};

const UpgradePopupContext = createContext<UpgradePopupContextProps>({
    isOpen: false,
    event: UpgradeEvent.MAX_PROMPT,
    context: {},
    onClose: noop,
    openUpgradePopup: noop,
    closeUpgradePopup: noop,
    isForPromotion: false,
    dialogProps: undefined,
});

export const UpgradePopupContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [event, setEvent] = useState(UpgradeEvent.MAX_PROMPT);
    const [context, setContext] = useState({});
    const [onClose, setOnClose] = useState<() => void>(noop);
    const [isForPromotion, setIsForPromotion] = useState(false);
    const [dialogProps, setDialogProps] =
        useState<Omit<DialogProps, "open" | "onClose" | "PaperProps" | "style" | "children">>();

    const openUpgradePopup = useCallback(
        ({ event, context: _context = {}, onClose = noop, isForPromotion, dialogProps }: OpenUpgradePopupProps) => {
            Mixpanel.track("Upgrade Popup", { event, ...context });
            setEvent(event);
            setIsOpen(true);
            setOnClose(onClose);
            setContext(_context);
            setIsForPromotion(isForPromotion || false);
            setDialogProps(dialogProps);
        },
        [context]
    );

    const closeUpgradePopup = useCallback(() => {
        setIsOpen(false);
        onClose?.();
        setIsForPromotion(false);
        setDialogProps(undefined);
        // delay resetting props so that the UI does not change on close animation fade away
        setTimeout(() => {
            setEvent(UpgradeEvent.MAX_PROMPT);
        }, 300);
    }, [onClose]);

    return (
        <UpgradePopupContext.Provider
            value={{
                isOpen,
                event,
                context,
                isForPromotion,
                dialogProps,
                onClose,
                openUpgradePopup,
                closeUpgradePopup,
            }}>
            {children}
        </UpgradePopupContext.Provider>
    );
};

export const useUpgradePopupContextSelector = <T,>(selector: (state: UpgradePopupContextProps) => T) =>
    useContextSelector(UpgradePopupContext, selector);
