import { difference } from "lodash-es";
import { intersection } from "lodash-es";

/**
 * returns the elements that are in arr1 - arr2
 * @param arr1
 * @param arr2
 */
export const arraysDifference = <T>(arr1: T[] = [], arr2: T[] = []): T[] => {
    return difference(arr1, arr2);
};

/**
 * returns the elements that are in arr1 and arr2
 * @param arr1
 * @param arr2
 */
export const arraysIntersection = <T>(arr1: T[] = [], arr2: T[] = []): T[] => {
    return intersection(arr1, arr2);
};

/**
 * returns true if the arrays are equal
 * @param arr1
 * @param arr2
 */
export const arraysEquality = <T>(arr1: T[] = [], arr2: T[] = []): boolean => {
    return arr1.length === arr2.length && intersection(arr1, arr2).length === arr1.length;
};

export const toggleArrayElements = <T>(array: T[], elements: T[], predicate = (a: T, b: T) => a === b): T[] => {
    const result = [...array];

    for (const element of elements) {
        const itemIndex = result.findIndex(item => predicate(item, element));
        if (itemIndex !== -1) {
            result.splice(itemIndex, 1);
        } else {
            result.push(element);
        }
    }
    return result;
};

/**
 * Returns a shuffled array
 * @param array
 */
export const shuffleArray = <T>(array: T[]): T[] => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = newArray[i];
        newArray[i] = newArray[j];
        newArray[j] = temp;
    }
    return newArray;
};

/**
 * Returns a chunked array
 * @param array
 * @param chunkSize
 */
export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
    if (chunkSize < 1) throw new Error("chunkSize must be greater than 0");

    if (chunkSize >= array.length) return [array];

    const arr: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        arr.push(array.slice(i, i + chunkSize));
    }
    return arr;
};

/**
 * Inserts an item into an array at a given index
 * @param arr
 * @param index
 * @param newItem
 */
export const insertElementAt = <T>(arr: T[], index: number, newItem: T): T[] => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
];

export const asyncFilter = async <T>(arr: T[], predicate: (item: T) => Promise<boolean>) => {
    const results = await Promise.all(arr.map(predicate));
    return arr.filter((_, index) => results[index]);
};

/**
 * Returns the sum of a number array
 * @param arr
 * @returns
 */
export const arraySum = (arr: number[]) => arr?.reduce((a, b) => a + b, 0);

export const arraySwap = <T>(arr: T[], i: number, j: number) => {
    const res = [...arr];
    const [removed] = res.splice(i, 1);
    res.splice(j, 0, removed);
    return res;
};

/**
 * Removes the Nth item of an array
 * @param array
 * @returns
 */
export const removeItemAtIndex = <ItemType>(array: ItemType[], i: number) => [
    ...array.slice(0, i),
    ...array.slice(i, array.length),
];

/**
 * Returns an array filled with the same value
 */
export const fillArray = <T>(length: number, value: T): T[] => {
    return Array.from({ length }, () => value);
};
