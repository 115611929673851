/** Represents icon size values for consistent styling. */
export const iconSizes = {
    XS: 12,
    SM_S: 14,
    SM: 18,
    MD_S: 20,
    MD: 24,
    MD_M: 30,
    LG: 38,
    XL: 50,
} as const;

export const strokeWidth = {
    thin: 1,
    normal: 2.2,
    bold: 3,
} as const;
